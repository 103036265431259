import { useEffect } from 'react';


interface PageTitleProps {
    title: string;
}

export function PageTitle({ title }: PageTitleProps) {
    useEffect(() => {
        const originalTitle = document.title; // Save the current title
        document.title = title;

        // Restore the original title when the component unmounts
        return () => {
            document.title = originalTitle;
        };
    }, [title]); // Update only when the title changes

    return null;
}
