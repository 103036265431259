import { useEffect } from 'react';


// This hook accepts a function that should return true if there are unsaved changes
export default function useConfirmOnLeave(
    hasUnsavedChanges: () => boolean,
    isEnabled: boolean
) {
    useEffect(() => {

        if (!isEnabled || import.meta.env.MODE === 'development') return undefined;// Vite hot module replacement would trigger the confirmation in development

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (hasUnsavedChanges()) {
                event.preventDefault(); // Standard for most browsers
                event.returnValue = ''; // Required for some browsers
                return 'You have unsaved changes! Are you sure you want to leave?'; // Some browsers may show this message
            }
            return undefined;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [hasUnsavedChanges, isEnabled]);
}
