import { MeasurementIntervalStateBase } from '../../types/measurementIntervalState.js';
import { isHelpMeStatus } from '../measurement-interval/isHelpMeStatus.js';
import { requiresMeasuring } from '../measurement-interval/requiresMeasuring.js';

type MeasurementIntervalState = MeasurementIntervalStateBase<any>;


export function getMeasurementsIssues(table: MeasurementIntervalState[]): number {
    let numIssues = 0;
    for (const row of table) {
        if (isHelpMeStatus(row) || requiresMeasuring(row)) {
            numIssues++;
        }
    }
    return numIssues;
}
