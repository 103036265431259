

/* Returns position of equipment in the facory. Results are 0-indexed and 
the coordinates begin from the bottom right */
export default function getEquipmentCoords(equipment: string): Position | null {
    switch (equipment) {
        case 'M532_5': return { col: 0, row: 2 };
        case 'Takamaz_XY-2000_2': return { col: 0, row: 3 };
        case 'BO38TE_12': return { col: 0, row: 4 };
        case 'HS38MH-5AX_11': return { col: 0, row: 5 };
        case 'M532_6': return { col: 1, row: 2 };
        case 'BO386VE_III_8': return { col: 1, row: 3 };
        case 'BO38TE_9': return { col: 1, row: 4 };
        case 'Takamaz_XY-2000': return { col: 1, row: 5 };
        case 'M532_3': return { col: 2, row: 0 };
        case 'M532_2': return { col: 2, row: 1 };
        case 'M532_1': return { col: 2, row: 2 };
        case 'BO326E-III_13': return { col: 2, row: 3 };
        case 'BO326E-II_4': return { col: 2, row: 4 };
        case 'BS32_2': return { col: 2, row: 5 };
        case 'M532_4': return { col: 3, row: 0 };
        case 'L32_2': return { col: 3, row: 1 };
        case 'L32_1': return { col: 3, row: 2 };
        case 'WECO326-II_16': return { col: 3, row: 3 };
        case 'HS327_10': return { col: 3, row: 4 };
        case 'BS32_1': return { col: 3, row: 5 };
        case 'L20_2': return { col: 4, row: 0 };
        case 'Nomura_NN32JXB_24': return { col: 4, row: 1 };
        case 'L20_1': return { col: 4, row: 2 };
        case 'BO206E-III_6': return { col: 4, row: 3 };
        case 'BW209ZE-II_7': return { col: 4, row: 4 };
        case 'Weco126-III_14': return { col: 5, row: 1 };
        case 'L12_1': return { col: 5, row: 2 };
        case 'BO126E-III_5': return { col: 5, row: 3 };
        case 'BS20CE_3': return { col: 5, row: 4 };
    }
    reportError(`Uknown equipment: ${equipment}`);
    return null;
}

interface Position {
    row: number;
    col: number;
}
