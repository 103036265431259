

export function getSessionTimeRemainingStyle(
    sessionTimeRemaining: number | null,
    partCount: number | null | undefined,
    targetQuantity: number | null | undefined,
    inOfflineMode: boolean,
): React.CSSProperties {
    const warningPeriodMillis = 8 * 3_600_000;
    const considerCount = targetQuantity != null && targetQuantity > 0 && !inOfflineMode;
    const countReached = partCount != null && targetQuantity != null && partCount >= targetQuantity;
    const timerReached = sessionTimeRemaining != null && sessionTimeRemaining <= 0;
    const requiresMeasuring = considerCount ? countReached : timerReached;

    let cellStyle: React.CSSProperties = {};
    if (requiresMeasuring) {
        cellStyle = { color: 'red' };
    } else if (sessionTimeRemaining != null && sessionTimeRemaining <= warningPeriodMillis) {
        cellStyle = { color: 'orange' };
    }
    return cellStyle;
}
