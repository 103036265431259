import { useQueries, UseQueryResult } from '@tanstack/react-query';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ManagementViewKpis } from '../../../shared/types/managementViewKpi';
import { CncEquipment } from '../../../shared/types/mtlinkiTypes';
import { sortBy } from '../../../shared/utils/arrayUtils';
import { fetchCncEquipments, fetchIsAdmin, fetchManagementViewKpis } from '../../api/requests';
import NavBar from '../../components/navBar';
import { PageTitle } from '../../components/pageTitle';
import useFetchOnce from '../../hooks/useFetchOnce';
import { formatCycleTime, formatEfficiency, formatEurosPerHour, formatPartsPerHour, formatPartsProduced, formatPricePerItem, formatProduct, formatSessionStart, formatSetupTime } from '../../utils/fieldFormats';
import PriceDialog from './priceDialog';


export default function ManagementView() {

    const [priceDialogKpis, setPriceDialogData] = useState<ManagementViewKpis | null>(null);


    const { data: isAdmin } = useFetchOnce({
        queryKey: ['isAdmin'],
        queryFn: () => fetchIsAdmin(),
        refetchOnWindowFocus: false,
    });

    const { data: equipments } = useFetchOnce({
        queryKey: ['equipments'],
        queryFn: () => fetchCncEquipments(),
        select: (equipments) => sortBy([...equipments], it => it.displayName),
        refetchOnWindowFocus: false,
    });

    const equipmentKpis = useQueries({
        queries: equipments?.map(equipment => ({
            queryKey: ['management-view-kpis', equipment.id],
            queryFn: async () => await fetchManagementViewKpis(equipment.id),
            refetchOnWindowFocus: false,
        })) ?? [],
    });


    const handlePriceClicked = (kpis: ManagementViewKpis | undefined) => {
        if (!kpis || !kpis.product) return;
        if (!kpis.partName) {
            alert('Part number has not been set for this program.');
            return;
        }
        setPriceDialogData(kpis);
    }

    const handlePriceChanged = (product: string, price: number) => {
        equipmentKpis.filter(it => it.data?.product === priceDialogKpis?.product).forEach(it => it.refetch());
    }


    return (
        <>
            <PageTitle title='Management View' />
            <NavBar />
            <div className='content'>
                {(equipments && isAdmin != null) && <ManagementViewTable
                    equipments={equipments}
                    equipmentKpis={equipmentKpis}
                    isAdmin={isAdmin}
                    onPriceClicked={handlePriceClicked}
                />}
            </div>

            {priceDialogKpis && <PriceDialog
                managementViewKpis={priceDialogKpis}
                onClose={() => setPriceDialogData(null)}
                onPriceSubmitted={handlePriceChanged}
            />}
        </>
    );
}


interface ManagementViewTableProps {
    equipments: CncEquipment[];
    equipmentKpis: UseQueryResult<ManagementViewKpis, Error>[];
    isAdmin: boolean;
    onPriceClicked: (kpis: ManagementViewKpis | undefined) => void;
}

function ManagementViewTable({
    equipments,
    equipmentKpis,
    isAdmin,
    onPriceClicked,
}: ManagementViewTableProps) {
    return (
        <table id='managementViewTable'>
            <thead id='managementViewTableHeader'>
                <tr>
                    <th>Equipment</th>
                    <th>Part number</th>
                    <th className='datetimeColumn'>Start</th>
                    <th>Setup time</th>
                    <th className='cycleTime' title='Latest stable cycle time (5 parts produced in a row with the same time)'>
                        Current cycle time
                    </th>
                    <th className='cycleTime' title='Best median cycle time from all previous runs'>
                        Best cycle time
                    </th>
                    <th title='parts/h = (all parts count - discards) / production time after setup * one hour'>
                        Parts / h
                    </th>
                    <th title='actual parts produced - discards&#10;Latest 8 hours shown in parentheses.'>
                        Good parts
                    </th>
                    <th className='efficiencyFromStart'
                        title='efficiency = median cycle time * valid parts count / production time'>
                        Efficiency from start
                    </th>
                    <th className='narrowColumn'
                        title='efficiency = median cycle time * valid parts count / production time&#10;The formula only considers parts in the given shift'>
                        Yö
                    </th>
                    <th className='narrowColumn'
                        title='efficiency = median cycle time * valid parts count / production time&#10;The formula only considers parts in the given shift'>
                        Aamu
                    </th>
                    <th className='narrowColumn'
                        title='efficiency = median cycle time * valid parts count / production time&#10;The formula only considers parts in the given shift'>
                        Ilta
                    </th>
                    {isAdmin && <>
                        <th>Unit price</th>
                        <th className='narrowColumn'>€ / h</th>
                        <th className='narrowColumn'>Yö</th>
                        <th className='narrowColumn'>Aamu</th>
                        <th className='narrowColumn'>Ilta</th>
                    </>}
                </tr>
            </thead>
            <tbody>
                {equipments?.map((equipment, index) => {
                    const isLoading = equipmentKpis[index].isLoading;
                    const kpis = equipmentKpis[index]?.data;

                    return (
                        <tr key={index}>
                            <td className='textAlignLeft'>
                                <Link className='link-button--non-intrusive' to={`/machine-log#equipment=${equipment.id}`}>
                                    {equipment.displayName}
                                </Link>
                            </td>
                            {isLoading
                                ? <>
                                    <td>...</td>
                                    <td>...</td>
                                    <td>...</td>
                                    <td>...</td>
                                    <td>...</td>
                                    <td>...</td>
                                    <td>...</td>
                                    <td>...</td>
                                    <td>...</td>
                                    <td>...</td>
                                    <td>...</td>
                                    {isAdmin && <>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                        <td>...</td>
                                    </>}
                                </>
                                : <>
                                    <td title={kpis?.product}>{formatProduct(kpis?.partDisplayName)}</td>
                                    <td>{formatSessionStart(kpis?.sessionStart)}</td>
                                    <td>{formatSetupTime(kpis?.setupTime)}</td>
                                    <td>{formatCycleTime(kpis?.currentCycleTime)}</td>
                                    <td>{formatCycleTime(kpis?.bestCycleTime)}</td>
                                    <td>{formatPartsPerHour(kpis?.goodPartsPerHour)}</td>
                                    <td>{formatPartsProduced(kpis?.goodPartsProduced)}</td>
                                    <td>{formatEfficiency(kpis?.efficiency)}</td>
                                    <td>{formatEfficiency(kpis?.efficiencyNight)}</td>
                                    <td>{formatEfficiency(kpis?.efficiencyMorning)}</td>
                                    <td>{formatEfficiency(kpis?.efficiencyEvening)}</td>
                                    {isAdmin && <>
                                        <td className='editable' onClick={() => onPriceClicked(kpis)}>
                                            {formatPricePerItem(kpis?.pricePerItem)}
                                        </td>
                                        <td>{formatEurosPerHour(kpis?.eurosPerHour)}</td>
                                        <td>{formatEurosPerHour(kpis?.eurosPerHourNight)}</td>
                                        <td>{formatEurosPerHour(kpis?.eurosPerHourMorning)}</td>
                                        <td>{formatEurosPerHour(kpis?.eurosPerHourEvening)}</td>
                                    </>}
                                </>}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
