import { useMemo } from 'react';
import { RecordWithProtocol } from '../../types/sharedTypeImpl';


export interface RangeOption {
    key: RangeKey,
    count: number | null,
    label: string,
    isEnabled: boolean,
}

export type RangeKey = 'latest_10' | 'latest_20' | 'latest_30' | 'orderNumber' | 'batch';


export interface UseRangeOptionsProps {
    orderNumber: string | undefined,
    records: RecordWithProtocol[] | undefined,
    selectedRangeKey: RangeKey,
}

interface UseRangeOptionsReturn {
    rangeOptions: RangeOption[],
    numLatestRecords: number | null,
}


export function useRangeOptions({
    orderNumber,
    records,
    selectedRangeKey,
}: UseRangeOptionsProps
): UseRangeOptionsReturn {
    const rangeOptions: RangeOption[] = useMemo(() => {
        const numInBatch = records?.length ?? null;
        const numWithOrderNumber = records?.filter(it => it.orderNumber === orderNumber).length ?? null;
        const maxCount = numInBatch ?? 0;

        return [
            { key: 'latest_10', count: 10, label: 'Latest 10', isEnabled: maxCount >= 10 },
            { key: 'latest_20', count: 20, label: 'Latest 20', isEnabled: maxCount >= 20 },
            { key: 'latest_30', count: 30, label: 'Latest 30', isEnabled: maxCount >= 30 },
            {
                key: 'orderNumber',
                count: numWithOrderNumber,
                label: `Order number (${numWithOrderNumber})`,
                isEnabled: numWithOrderNumber != null
            },
            {
                key: 'batch',
                count: numInBatch,
                label: `Batch (${numInBatch})`,
                isEnabled: numWithOrderNumber != null
            },
        ]
    }, [orderNumber, records]);

    const numLatestRecords = rangeOptions.find(it => it.key === selectedRangeKey)!.count;

    return {
        rangeOptions,
        numLatestRecords,
    };
}
