import { Position, ProtocolBase } from '../types/protocol.js';
import { RecordWithProtocolBase } from '../types/recordWithProtocol.js';


export function getPositionValues<ObjectIdType>(
    position: Position,
    records: RecordWithProtocolBase<ObjectIdType>[],
): string[] {
    const positionNumber = position.positionNumber;

    const values: string[] = [];
    for (const record of records) {
        if (record.isDraft) continue;

        const positionIndex = record.protocol.positions.findIndex(it => it.positionNumber === positionNumber);
        if (positionIndex === -1) continue;
        if (record.protocol.positions[positionIndex].type === 'visualInspection') continue;
        if (record.protocol.positions[positionIndex].nominal != position.nominal) continue;

        const value = record.positionValues[positionIndex]?.value;
        if (value == null || value === '') continue;

        values.push(value as string);
    }
    return values;
}

export function getPositionIdxValues<ObjectIdType>(
    protocol: ProtocolBase<ObjectIdType>,
    records: RecordWithProtocolBase<ObjectIdType>[],
    activePositionIdx: number,
): string[] {
    return getPositionValues(protocol.positions[activePositionIdx], records);
}
