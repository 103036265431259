import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { CalibratedTool } from '../../shared/types/calibratedTool';
import { fetchCalibratedTools } from '../api/requests';
import ModalSubmitDialog from './modalSubmitDialog';
import TriStateToggle from './triStateToggle';


interface CalibratedToolDialogProps {
    isOpen: boolean;
    initialSelection?: string[];
    onCancel: () => void;
    onSubmit: (selectedTools: string[]) => void;
}

export default function CalibratedToolDialog({
    isOpen,
    initialSelection,
    onCancel,
    onSubmit,
}: CalibratedToolDialogProps) {
    const [selectedTools, setSelectedTools] = useState<string[]>(initialSelection ?? []);

    const { data: calibratedTools, error, isLoading } = useQuery({
        queryKey: ['calibratedTools'],
        queryFn: fetchCalibratedTools,
        select: (data: CalibratedTool[]) => data
            .filter(it => it.isEnabled)
            .sort((a, b) => a.name.localeCompare(b.name)),
    });


    useEffect(() => {
        if (isOpen) setSelectedTools(initialSelection ?? []);
    }, [initialSelection, isOpen])


    function handleSelectToggle(tool: CalibratedTool) {
        handleSelectChange(tool, !isSelected(tool));
    }

    function handleSelectChange(tool: CalibratedTool, isSelected: boolean) {
        if (isSelected) {
            setSelectedTools(Array.from(new Set([...selectedTools, tool.serialNumber])));
        } else {
            setSelectedTools(selectedTools.filter(it => it !== tool.serialNumber));
        }
    }

    function isSelected(tool: CalibratedTool): boolean {
        return selectedTools.some(it => it === tool.serialNumber);
    }

    function handleSubmit() {
        return onSubmit(selectedTools);
    }


    if (!isOpen) return null;
    return (
        <ModalSubmitDialog isOpen={isOpen} onCancel={onCancel} onSubmit={handleSubmit}>
            {error && <p>error</p>}
            {isLoading && <p>Loading...</p>}
            {calibratedTools &&
                <table className='table--calibrated-tools'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Serial number</th>
                            <th>Type</th>
                            <th>Is selected</th>
                        </tr>
                    </thead>
                    <tbody>
                        {calibratedTools?.map(it => (
                            <tr key={it.serialNumber} onClick={() => handleSelectToggle(it)}>
                                <td>{it.name}</td>
                                <td>{it.serialNumber}</td>
                                <td>{it.type}</td>
                                <td>
                                    <TriStateToggle
                                        selection={isSelected(it)}
                                        onSelectionChange={(isSelected) => handleSelectChange(it, isSelected)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </ModalSubmitDialog>
    )
}

