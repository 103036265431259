import { Point, Rect, Size } from '../../../shared/types/mathTypes';


export function getOffsetOnPan(
    currentMousePos: Point,
    panStartMousePos: Point,
    panStartOffset: Point,
    containerSize: Size,
    pdfViewSize: Size
): Point {
    const x = panStartOffset.x + (currentMousePos.x - panStartMousePos.x);
    const y = panStartOffset.y + (currentMousePos.y - panStartMousePos.y);
    const newOffsetFinal = ensureInBounds({ x, y }, containerSize, pdfViewSize);
    return newOffsetFinal;
    // return { x, y };
}

export function getOffsetOnZoom(
    currentScale: number,
    newScale: number,
    currentOffset: Point,
    mousePos: Point,
    containerSize: Size,
    pdfViewSize: Size,
    containerClientRect: Rect
): Point {
    const mouseX = mousePos.x - containerClientRect.left - currentOffset.x;
    const mouseY = mousePos.y - containerClientRect.top - currentOffset.y;

    // Calculate how much the mouse is from the top-left of the canvas in percentage
    const mousePercentX = mouseX / containerClientRect.width;
    const mousePercentY = mouseY / containerClientRect.height;

    // Calculate the new offset
    const width = containerClientRect.width;
    const height = containerClientRect.height;
    const scaleRatio = newScale / currentScale;
    const x = currentOffset.x - (mousePercentX * width) * scaleRatio + mousePercentX * width;
    const y = currentOffset.y - (mousePercentY * height) * scaleRatio + mousePercentY * height;

    // Adjust new offsets within bounds
    const newOffsetFinal = ensureInBounds({ x, y }, containerSize, pdfViewSize);
    return newOffsetFinal;
}

export function defaultZoomPanValues(
    containerSize: Size,
    pdfSize: Size,
): [Point, number] {
    const scale = Math.min(containerSize.width / pdfSize.width, containerSize.height / pdfSize.height);
    const x = (containerSize.width - pdfSize.width * scale) / 2;
    const y = (containerSize.height - pdfSize.height * scale) / 2;
    return [{ x, y }, scale];
}

export function canvasToPdfCoords(canvasCoords: Point, scale: number): Point {
    return {
        x: canvasCoords.x / scale,
        y: canvasCoords.y / scale,
    };
}

export function pdfToContainerCoords(pdfCoords: Point, offset: Point, scale: number): Point {
    return {
        x: pdfCoords.x * scale + offset.x,
        y: pdfCoords.y * scale + offset.y,
    };
}

export function screenToPdfCoords(screenCoords: Point, offset: Point, scale: number): Point {
    return {
        x: (screenCoords.x - offset.x) / scale,
        y: (screenCoords.y - offset.y) / scale,
    };
}

export function pdfToScreenCoords(pdfCoords: Point, offset: Point, scale: number): Point {
    return {
        x: pdfCoords.x * scale + offset.x,
        y: pdfCoords.y * scale + offset.y,
    };
}


function ensureInBounds(
    offset: Point,
    containerSize: Size,
    pdfViewSize: Size
): Point {
    let x = offset.x;
    if (pdfViewSize.width > containerSize.width) {
        // Canvas is wider than the container
        x = Math.min(x, containerSize.width / 2);
        x = Math.max(x, containerSize.width / 2 - pdfViewSize.width);
    } else {
        // Canvas is smaller or equal to the container width
        const maxOutside = pdfViewSize.width / 2;
        x = Math.min(x, containerSize.width - maxOutside);
        x = Math.max(x, -maxOutside);
    }

    let y = offset.y;
    if (pdfViewSize.height > containerSize.height) {
        // Canvas is taller than the container
        y = Math.min(y, containerSize.height / 2);
        y = Math.max(y, containerSize.height / 2 - pdfViewSize.height);
    } else {
        // Canvas is smaller or equal to the container height
        const maxOutside = pdfViewSize.height / 2;
        y = Math.min(y, containerSize.height - maxOutside);
        y = Math.max(y, -maxOutside);
    }

    return { x, y };
}
