import { ALL_EQUIPMENTS } from '../../../shared/types/oeeRow';
import { OeeRow } from '../../types/sharedTypeImpl';


export enum GraphValueType {
    Availability = 'availability',
    Quality = 'quality',
    Scraps = 'scraps',
    Performance = 'performance',
    Utilization = 'utilization',
    Oee = 'oee',
    OeeWithoutSetup = 'oeeWithoutSetup',
    Teep = 'teep',
}
export const { Availability, Quality, Scraps, Performance, Utilization, Oee, OeeWithoutSetup, Teep } = GraphValueType;


export function getGraphValueAverage(oeeRows: OeeRow[], graphValueType: string) {
    let weightedValueSum = 0;
    let weightSum = 0;
    for (const oeeRow of oeeRows) {
        const { value, weight } = getGraphValue(oeeRow, graphValueType);
        weightedValueSum += value * weight;
        weightSum += weight;
    }

    const averageValue = weightSum > 0
        ? weightedValueSum / weightSum
        : 0;
    return {
        value: averageValue,
        weight: weightSum,
    }
}

export function getGraphValue(oeeRow: OeeRow, graphValueType: string) {

    let value: number;
    let weight: number;
    if (graphValueType === Availability) {
        const shouldBeProducingTime = oeeRow.productionTime + oeeRow.stopTime;
        value = shouldBeProducingTime > 0 ? oeeRow.productionTime / shouldBeProducingTime : 0;
        weight = shouldBeProducingTime;
    } else if (graphValueType === Quality) {
        value = oeeRow.quality ?? 0;
        weight = oeeRow.equipment === ALL_EQUIPMENTS
            ? oeeRow.productionTime
            : oeeRow.totalPartCount ?? 0;
    } else if (graphValueType === Scraps) {
        value = 1 - (oeeRow.quality ?? 1);
        weight = oeeRow.equipment === ALL_EQUIPMENTS
            ? oeeRow.productionTime
            : oeeRow.totalPartCount ?? 0;
    } else if (graphValueType === Performance) {
        value = Math.min(oeeRow.performance ?? 0, 1.0);
        weight = oeeRow.productionTime;
    } else if (graphValueType === Utilization) {
        value = oeeRow.utilization;
        weight = oeeRow.realTime;
    } else if (graphValueType === Oee) {
        value = oeeRow.oee ?? 0;
        weight = oeeRow.connectedTime;
    } else if (graphValueType === OeeWithoutSetup) {
        value = oeeRow.oeeWithoutSetup ?? 0;
        weight = oeeRow.connectedTime - oeeRow.setupTime;
    } else if (graphValueType === Teep) {
        value = oeeRow.teep;
        weight = oeeRow.realTime;
    } else {
        throw new Error('Invalid graphValueType: ' + graphValueType);
    }

    return { value, weight };
}
