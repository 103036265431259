import { useEffect, useRef } from 'react';
import { Point, Rect, Size } from '../../../shared/types/mathTypes';
import { defaultZoomPanValues, getOffsetOnPan, getOffsetOnZoom } from './zoomPanUtils';

interface PanStart {
    offset: Point;
    mousePos: Point;
}


interface UseZoomPanProps {
    containerRef: React.RefObject<HTMLDivElement>;
    contentRef: React.RefObject<HTMLCanvasElement>;
    // pdfSize: React.RefObject<Size | null>;
    pdfSize: Size | null;
    scale: number;
    setScale: (scale: number) => void;
    offset: React.MutableRefObject<Point>;
    onZoomPan: () => void;
}

export default function useZoomPan({
    containerRef,
    contentRef,
    pdfSize,
    scale,
    setScale,
    offset,
    onZoomPan,
}: UseZoomPanProps) {
    const zoomDelta: number = 0.5;

    const panStart = useRef<PanStart | null>(null);


    const fitToHeight = () => {
        if (!pdfSize) return;

        const containerSize: Size = { width: containerRef.current!.clientWidth, height: containerRef.current!.clientHeight };
        const [newOffset, newScale] = defaultZoomPanValues(containerSize, pdfSize);
        offset.current = newOffset;

        if (newScale != scale) {
            setScale(newScale);
        } else {
            onZoomPan();
        }
    }

    const onMouseWheel = (e: WheelEvent) => {
        e.preventDefault();
        if (!pdfSize) return;
        const container = containerRef.current!;
        const content = contentRef.current!;

        const zoomIn = e.deltaY < 0;
        const mousePos = { x: e.clientX, y: e.clientY };

        const newScale = zoomIn
            ? scale + zoomDelta
            : Math.max(0.5, scale - zoomDelta);
        const containerSize: Size = { width: container.clientWidth, height: container.clientHeight };
        const pdfViewSize: Size = { width: content.clientWidth, height: content.clientHeight };
        const containerClientRect = container.getBoundingClientRect() as Rect;
        const pdfViewClientRect = content.getBoundingClientRect() as Rect;

        //Wheel events may be triggered faster than the browser can render the view.
        //Only update the view if the scale and offset are close to what we expect.
        const realScale = pdfViewClientRect.width / pdfSize.width;
        const realOffset = {
            x: pdfViewClientRect.left - containerClientRect.left,
            y: pdfViewClientRect.top - containerClientRect.top
        };
        if (Math.abs(scale - realScale) > 0.005) return;
        if (Math.abs(offset.current.x - realOffset.x) > 0.5) return;

        offset.current = getOffsetOnZoom(
            scale,
            newScale,
            offset.current,
            mousePos,
            containerSize,
            pdfViewSize,
            containerClientRect
        );
        setScale(newScale);
        // onZoomPan(); //Setting scale already updates view. Having this might cause flickering
    };

    const onMouseDown = (e: React.MouseEvent) => {
        if (!pdfSize) return;
        if (e.button !== 0) return; // Only continue if the button is mouse1
        panStart.current = {
            offset: { ...offset.current },
            mousePos: { x: e.clientX, y: e.clientY },
        };
    }

    const onMouseMove = (e: React.MouseEvent) => {
        if (!pdfSize) return;
        if (!panStart.current) return;

        const container = containerRef.current!;
        const content = contentRef.current!;
        const currentMousePos: Point = { x: e.clientX, y: e.clientY };
        const containerSize: Size = { width: container.clientWidth, height: container.clientHeight };
        const pdfViewSize: Size = { width: content.clientWidth, height: content.clientHeight };

        offset.current = getOffsetOnPan(
            currentMousePos,
            panStart.current.mousePos,
            panStart.current.offset,
            containerSize,
            pdfViewSize,
        );
        onZoomPan();
    }

    //On mouse up, even if outside the container
    useEffect(() => {
        const onMouseUp = (e: MouseEvent) => {
            if (e.button !== 0) return; // Only continue if the button is mouse1
            panStart.current = null;
        }

        window.addEventListener('mouseup', onMouseUp);
        return () => {
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, []);


    return {
        fitToHeight,
        onMouseWheel,
        onMouseDown,
        onMouseMove,
    }
}
