import * as marked from 'marked';
import { useRef } from 'react';
import useFetchOnce from '../../hooks/useFetchOnce';
import { getTextWithRedirect } from '../../utils/fetchRequests';


export default function ReleaseNotes() {
    const releaseNotesDivRef = useRef<HTMLDivElement>(null);

    useFetchOnce({
        queryKey: ['releaseNotes'],
        queryFn: async () => await getTextWithRedirect('/api/v1/release-notes', {}),
        onSuccess: (releaseNotesRaw: string) => {
            const releaseNotesHtml = marked.parse(releaseNotesRaw) as string;
            releaseNotesDivRef.current!.innerHTML = releaseNotesHtml;
        },
    });

    return (
        <div className='div__release-notes'>
            <h1>Release notes</h1>
            <div ref={releaseNotesDivRef}>Loading...</div>
            <img src='/cofundedbytheEU_logo.png' width='500' />
        </div>
    );
}