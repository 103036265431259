
export default function getStatusColor(status: string | null): string {
    switch (status) {
        case 'OPERATE': return '#00B800';
        case 'SETUP': return '#00A2E8';
        case 'ALARM': return '#FF0000';
        case 'EMERGENCY': return '#FF00FF';
        case 'SUSPEND': return '#FF9B32';
        case 'STOP': return '#E5E33F';
        case 'MANUAL': return '#02CDFC';
        case 'WARMUP': return '#B22222';
        case 'DISCONNECT':
        default: return '#A9A9A9';
    }
}
