import { useQueries, useQuery } from '@tanstack/react-query';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { sortByDesc } from '../../../shared/utils/arrayUtils';
import { fetchSessionKpis, fetchSessions } from '../../api/requests';
import { Session } from '../../types/sharedTypeImpl';
import delay from '../../utils/delay';
import { formatCycleTime, formatDatetime, formatEfficiency, formatKpiAllAndRecent, formatPartsPerHour, formatPartsProduced, formatQuality, formatSetupTime } from '../../utils/fieldFormats';
import { SelectedEquipment, SelectedPart } from './types';


export interface SessionsTableRef {
    refetchCurrentSessionKpis: () => void;
}

interface SessionsTableProps {
    selectedEquipment: SelectedEquipment | null;
    selectedPart: SelectedPart | null;
    selectedSession: Session | null;
    setSelectedSession: (session: Session | null) => void;
}

const SessionsTable = forwardRef<SessionsTableRef, SessionsTableProps>(({
    selectedEquipment,
    selectedPart,
    selectedSession,
    setSelectedSession,
}: SessionsTableProps
    , ref) => {

    const abortController = useRef(new AbortController());

    const { data: sessions } = useQuery({
        queryKey: ['sessions', selectedPart],
        queryFn: () => fetchSessions(selectedPart!.partName, selectedPart!.product),
        select: (sessions) => sortByDesc([...sessions], it => it.start),
        enabled: selectedPart !== null,
        refetchInterval: 10 * 60_000,
    });

    //Select current session on sessions change
    useEffect(() => {
        if (!sessions || sessions.length == 0) {
            setSelectedSession(null);
            return;
        }

        const isAlreadySelected = selectedSession && sessions.some(it => it === selectedSession);
        if (isAlreadySelected) return;

        const sameSession = selectedSession &&
            sessions.find(it => it.equipment === selectedSession.equipment && +it.start === +selectedSession.start);
        if (sameSession) {
            setSelectedSession(sameSession);
        } else {
            const runningSession = sessions.find(it => it.equipment === selectedEquipment?.id && it.end === null) ?? null;
            setSelectedSession(runningSession);
        }
    }, [selectedSession, selectedEquipment, sessions, setSelectedSession]);

    //Cancel kpi requests, when session changes (otherwise the browser concurrent fetch limit may be reached).
    //Do it immediately on equipment or product change, since otherwise the sessions themselves could not be loaded.
    useEffect(() => {
        return () => {
            abortController.current.abort();
            abortController.current = new AbortController();
        }
    }, [sessions, selectedEquipment, selectedPart?.partName, selectedPart?.product]);

    //Fetch kpis for each session
    const kpiQueries = useQueries({
        queries: sessions?.map(session => ({
            queryKey: ['machine-log-kpis', session.equipment, session.product, session.start.toISOString()],
            queryFn: async () => {
                await delay(30);//Allow operations fetch to start first
                return await fetchSessionKpis(session, abortController.current);
            },
            refetchOnWindowFocus: false,
        })) ?? [],
    });

    useImperativeHandle(ref, () => ({
        refetchCurrentSessionKpis: () => {
            if (!sessions || !selectedSession) return;
            const index = sessions.findIndex(it => it === selectedSession);
            kpiQueries[index].refetch();
        }
    }));


    if (sessions == null) return null;
    if (sessions.length == 0) return (
        <p>
            No batches found
            {selectedPart?.partName && ' (Note: Part names should not contain the revision)'}
        </p>
    );
    return (
        <table style={{ marginTop: '16px' }}>
            <thead>
                <tr>
                    <th>Rev</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Equipment</th>
                    <th title='Median cycle time after setup&#10;Latest 8 hours shown in parentheses'>
                        Cycle time
                    </th>
                    <th
                        title='efficiency = median cycle time * valid parts count / production time&#10;Latest 8 hours shown in parentheses'>
                        Efficiency
                    </th>
                    <th
                        title='parts/h = (actual parts produced - discards) / production time after setup * one hour&#10;Latest 8 hours shown in parentheses.'>
                        Parts / hour
                    </th>
                    <th title="good parts / all parts&#10;Latest 8 hours shown in parentheses.">
                        Quality
                    </th>
                    <th title='actual parts produced - discards&#10;Latest 8 hours shown in parentheses.'>
                        Good parts
                    </th>
                    <th>Setup time</th>
                </tr>
            </thead>

            <tbody>
                {
                    sessions?.map((session, index) => {
                        const isLoading = kpiQueries[index].isLoading;
                        const kpis = kpiQueries[index].data;

                        return (
                            <tr
                                key={session.equipment + +session.start}
                                className={selectedSession === session ? 'highlight--selected' : ''}
                                onClick={() => setSelectedSession(session)}
                            >
                                <td>{session.revision ?? ''}</td>
                                <td>{formatDatetime(session.start)}</td>
                                <td>{formatDatetime(session.end)}</td>
                                <td>{session.equipmentDisplayName}</td>
                                <td>{isLoading ? '...'
                                    : formatKpiAllAndRecent(
                                        formatCycleTime(kpis?.kpisOverall?.cycleTimeMedian),
                                        formatCycleTime(kpis?.kpisRecent?.cycleTimeMedian)
                                    )
                                }</td>
                                <td>{isLoading ? '...'
                                    : formatKpiAllAndRecent(
                                        formatEfficiency(kpis?.kpisOverall?.efficiency),
                                        formatEfficiency(kpis?.kpisRecent?.efficiency)
                                    )
                                }</td>
                                <td>{isLoading ? '...'
                                    : formatKpiAllAndRecent(
                                        formatPartsPerHour(kpis?.kpisOverall?.goodPartsPerHour),
                                        formatPartsPerHour(kpis?.kpisRecent?.goodPartsPerHour)
                                    )
                                }</td>
                                <td>{isLoading ? '...'
                                    : formatKpiAllAndRecent(
                                        formatQuality(kpis?.kpisOverall?.quality),
                                        formatQuality(kpis?.kpisRecent?.quality)
                                    )
                                }</td>
                                <td>{isLoading ? '...' : formatPartsProduced(kpis?.kpisOverall?.goodPartsProduced)}</td>
                                <td>{isLoading ? '...' : formatSetupTime(kpis?.activeSetupTime)}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table >
    );
});
export default SessionsTable;
