import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { ProtocolReview } from '../../../shared/types/protocol';
import { patchProtocol, upsertProtocol } from '../../api/requests';
import useAutoSave from '../../hooks/useAutoSave';
import { ProtocolImageEntry } from '../../types/protocolImageEntry';
import { Protocol, ProtocolDraft } from '../../types/sharedTypeImpl';
import { draftToProtocol } from '../../utils/conversion';


interface UseProtocolSaveProps {
    protocol: ProtocolDraft | null;
    setProtocol: Dispatch<SetStateAction<ProtocolDraft | null>>;
    pdfData: Uint8Array | null;
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
    isViewMode: boolean;
    isFinalSavedRef: React.MutableRefObject<boolean>;
    onFinalSaved: () => void;
    onReviewSaved: () => void;
}

export default function useProtocolSave({
    protocol,
    setProtocol,
    pdfData,
    isDirty,
    setIsDirty,
    isViewMode,
    isFinalSavedRef,
    onFinalSaved,
    onReviewSaved,
}: UseProtocolSaveProps) {
    const [isAutoSaving, setIsAutoSaving] = useState(false);
    const [isFinalSaving, setIsFinalSaving] = useState(false);
    const isFinalSavePendingRef = useRef(false);

    useAutoSave({
        isViewMode,
        isFinalized: isViewMode,
        isFinalSaving,
        isDirty,
        autoSaveCallback: async () => await handleDraftSave()
    });


    const handleDraftSave = async () => {
        if (!protocol || isAutoSaving || isFinalSaving || isFinalSavedRef.current) return;
        setIsAutoSaving(true);
        console.log('Draft save started');
        await saveProtocol(protocol);

        setIsAutoSaving(false);
        console.log('Draft save ended');
        if (isFinalSavePendingRef.current) {
            isFinalSavePendingRef.current = false;
            handleFinalSave(protocol);
        }
    }

    const handleFinalSave = async (protocolDraft: ProtocolDraft) => {
        if (isFinalSaving || isFinalSavePendingRef.current || isFinalSavedRef.current) return;
        if (isAutoSaving) {
            isFinalSavePendingRef.current = true;
            return;
        }

        setIsFinalSaving(true);
        console.log('Final save started');

        const protocolFinal = {
            ...protocolDraft,
            status: 'review' as const,
        }
        const savedProtocol = await saveProtocol(protocolFinal);

        setIsFinalSaving(false);
        console.log('Final save ended');
        if (savedProtocol) {
            isFinalSavedRef.current = true;
            onFinalSaved();
        }
    }

    const saveProtocol = async (protocolDraft: ProtocolDraft): Promise<Protocol | null> => {
        if (!protocol) return null;
        try {
            setIsAutoSaving(true);

            const imageEntries = getPositionImages(protocolDraft);
            const pdf = (pdfData && protocol.pdfFileName == null) ? pdfData : null;
            if (pdf && pdf.length === 0) {
                throw new Error('Attempting to save empty pdf file');
            }

            const protocolApi = draftToProtocol(protocolDraft);
            const savedProtocol = await upsertProtocol(protocolApi, pdf, imageEntries);

            setProtocol(protocol => {
                return {
                    ...protocol!,
                    _id: savedProtocol._id,
                    pdfFileName: savedProtocol.pdfFileName,
                }
            });
            setIsDirty(false);
            return savedProtocol;
        } catch (error) {
            alert('Failed to save');
            return null;
        } finally {
            setIsAutoSaving(false);
        }
    }

    const handleReviewSave = async (review: ProtocolReview) => {
        if (!protocol) return;
        try {
            setIsFinalSaving(true);

            const success = await patchProtocol(protocol._id!, {
                reviews: [...(protocol.reviews ?? []), review],
                status: review.approved ? 'approved' : 'rejected',
            });
            if (success) {
                onReviewSaved();
            } else {
                alert('Failed to save');
            }
        } catch (error) {
            alert('Failed to save');
        } finally {
            setIsFinalSaving(false);
        }
    }

    const getPositionImages = (protocol: ProtocolDraft): ProtocolImageEntry[] => {
        const images = [];
        for (let i = 0; i < protocol.positions.length; i++) {
            if (!protocol.positions[i].imageFile) continue;
            images.push({
                image: protocol.positions[i].imageFile!,
                positionIdx: i
            });
        }
        return images;
    }


    return {
        isSaving: isAutoSaving || isFinalSaving,
        handleDraftSave,
        handleFinalSave,
        handleReviewSave,
    }
}
