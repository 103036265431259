import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './app';
import { initErrorLogging } from './utils/errorLogging';
import { trpc, trpcClient } from './utils/trpc';


initErrorLogging();

const queryClient = new QueryClient({});


ReactDOM.createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <trpc.Provider queryClient={queryClient} client={trpcClient}>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </trpc.Provider>
    </StrictMode>
)
