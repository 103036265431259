import { useEffect, useRef } from 'react';
import { MeasurementDelay } from '../../../shared/types/measurementDelay';
import { sumOf } from '../../../shared/utils/arrayUtils';
import { Week } from '../../types/week';
import { LIVE_GRAPH_MED } from '../../utils/liveGraphColors';
import { drawGraph } from './drawGraph';
import { ReportGraphData } from './reportGraphData';


interface MeasurementDelaysGraphProps {
    style?: React.CSSProperties;
    measurementDelays: MeasurementDelay[];
    weeks: Week[];
}

export default function MeasurementDelaysGraph({
    style,
    measurementDelays,
    weeks,
}: MeasurementDelaysGraphProps) {
    const graphContainerRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const transformedData = transformData(measurementDelays, weeks);
        drawGraph(graphContainerRef.current!, transformedData);
    }, [measurementDelays, weeks]);


    function transformData(measurementDelays: MeasurementDelay[], weeks: Week[]): ReportGraphData[] {
        const data: ReportGraphData[] = [];
        for (const week of weeks) {
            const measurementDelaysWeek = measurementDelays.filter(it =>
                it.measurementTime >= week.start && it.measurementTime < week.end
            );

            const delaySumMillis = sumOf(measurementDelaysWeek, it => it.delay ?? 0);
            const delaySumHours = delaySumMillis / 3600_000;
            data.push({
                ...week,
                hoursLabel: delaySumHours.toFixed(0) + 'h',
                mainValue: delaySumHours,
                barColor: LIVE_GRAPH_MED,
            });
        }
        return data;
    }


    return (
        <div style={{ ...style, height: '100%' }}
            ref={graphContainerRef}
        />
    )
}