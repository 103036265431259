
export const ALL_EQUIPMENTS = 'All equipments' as const;
export const MIXED_PRODUCTS = 'Mixed' as const;

export type OeeWindowType = 'batch' | 'day' | 'shift' | 'batch/shift' | 'hour' | 'live';


export interface OeeRowBase {
    start: Date;
    end: Date;
    windowType: OeeWindowType;
    weekday: number;
    weekNumber: number;
    shift: string;
    shiftName: string;

    //aggregate inputs
    equipment: typeof ALL_EQUIPMENTS | string;
    product: typeof MIXED_PRODUCTS | string | null;
    realTime: number;
    rawOperateTime: number;
    productionTime: number;
    stopTime: number;
    setupTime: number;
    connectedTime: number;
    totalPartCount: number | null;// Null, if the row combines different products
    goodPartCount: number | null;// Null, if the row combines different products

    //oee outputs
    utilization: number;
    availability: number | null;
    stop: number | null;
    setup: number | null;
    performance: number | null;
    quality: number | null;
    oee: number | null;
    oeeWithoutSetup: number | null;
    teep: number;
}

export type OeeRowFront = OeeRowBase & {
    partDisplayName: string | null;
};
