import { useEffect, useState } from 'react';
import { Equipment } from '../../../shared/types/equipment';
import { postCustomEquipment } from '../../api/requests';
import ModalSubmitDialog from '../../components/modalSubmitDialog';


interface CustomEquipmentDialogProps {
    equipments: Equipment[];
    onEquipmentAdded: (equipment: Equipment) => void;
    onClose: () => void;
}

export default function CustomEquipmentDialog({
    equipments,
    onEquipmentAdded,
    onClose,
}: CustomEquipmentDialogProps) {

    const [id, setId] = useState<string>('');
    const [displayName, setDisplayName] = useState<string>('');

    useEffect(() => {
        setId('');
        setDisplayName('');
    }, []);

    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    async function handleSubmit() {
        const equipment: Equipment = {
            id,
            displayName,
            source: 'custom'
        };

        if (equipments.some(it => it.id.toUpperCase() === equipment.id.toUpperCase())) {
            alert('Id must be unique');
            return;
        }

        await postCustomEquipment(equipment);

        onEquipmentAdded(equipment);
        onClose();
    }

    return (
        <ModalSubmitDialog
            frameStyle={{ width: '360px' }}
            isOpen={true}
            onCancel={onClose}
            onSubmit={handleSubmit}
        >
            <div>
                <div>
                    <div>Id (cannot be modified)</div>
                    <input
                        className='dialog-form__input'
                        type='text'
                        value={id}
                        onChange={e => setId(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div className='dialog-form__field--with-margins' >
                    <div>Display name</div>
                    <input
                        className='dialog-form__input'
                        type='text'
                        value={displayName}
                        onChange={e => setDisplayName(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </div>
        </ModalSubmitDialog>
    );
}
