import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { NUM_SPC_CHECKS } from '../../../shared/services/spc/spcCheckPosition';
import { fetchEnabledSpcChecks, putEnabledSpcChecks } from '../../api/requests';
import { ProtocolFinal } from '../../types/sharedTypeImpl';


interface UseEnabledSpcChecksProps {
    product: string,
    protocol: ProtocolFinal | undefined,
}

export default function useEnabledSpcChecks({
    product,
    protocol,
}: UseEnabledSpcChecksProps) {
    const [enabledSpcChecks, setEnabledSpcChecks] = useState<Map<string, boolean[]> | undefined>(undefined);
    const [loadedChecksApplied, setLoadedChecksApplied] = useState(false);

    const { data: loadedEnabledSpcChecks } = useQuery({
        queryKey: ['enabledSpcChecks', product],
        queryFn: async () => await fetchEnabledSpcChecks(product),
        refetchOnWindowFocus: false,
    })

    // Set initial enabled checks when protocol is loaded (if not already set)
    useEffect(() => {
        if (!protocol) return;
        if (enabledSpcChecks) return;
        const newEnabledChecks = new Map(
            protocol.positions.map(pos => [pos.positionNumber, Array(NUM_SPC_CHECKS).fill(true)])
        );
        setEnabledSpcChecks(newEnabledChecks);
    }, [protocol, enabledSpcChecks]);

    // Set enabled checks from API when loaded
    useEffect(() => {
        if (!loadedEnabledSpcChecks || !protocol || loadedChecksApplied) return;

        const newEnabledChecks = new Map<string, boolean[]>();
        for (const position of protocol.positions) { // Make sure the set of checks matches the protocol
            const posNr = position.positionNumber;
            const enabledChecks = loadedEnabledSpcChecks.find(it => it.positionNumber === posNr)?.enabledChecks
                ?? Array(NUM_SPC_CHECKS).fill(true);
            newEnabledChecks.set(posNr, enabledChecks);
        }
        setEnabledSpcChecks(newEnabledChecks);
        setLoadedChecksApplied(true);
    }, [loadedEnabledSpcChecks, protocol, loadedChecksApplied]);


    const handleSpcCheckChanged = async (positionNumber: string, colIdx: number, isEnabled: boolean) => {
        // Update the local state
        const newEnabledChecks = new Map(enabledSpcChecks);
        const newEnabledRow = [...newEnabledChecks.get(positionNumber)!];
        newEnabledRow[colIdx] = isEnabled;
        newEnabledChecks.set(positionNumber, newEnabledRow);
        setEnabledSpcChecks(newEnabledChecks);

        // Update the API
        await putEnabledSpcChecks(product, positionNumber, newEnabledRow);
    }

    return { enabledSpcChecks, onSpcCheckChanged: handleSpcCheckChanged };
}
