

export type MaintenanceOperationType = typeof MaintenanceOperationTypes[number];
export const MaintenanceOperationTypes = ['maintenance', 'repair'] as const;
export const MaintenanceOperationTypeLabels: Record<MaintenanceOperationType, string> = {
    maintenance: 'Maintenance',
    repair: 'Repair',
};

export interface MaintenanceOperationBase<ObjectIdType = string> {
    _id?: ObjectIdType;
    group: string;
    equipment: string;
    time: Date;
    operationType: MaintenanceOperationType;
    comment: string;
    operator: string;
}
