import { useEffect, useState } from 'react';
import { MaintenanceOperationType, MaintenanceOperationTypeLabels, MaintenanceOperationTypes } from '../../../shared/types/maintenanceOperation';
import { postMaintenanceOperation } from '../../api/requests';
import ModalDialog from '../../components/modalDialog';
import OperatorSelect from '../../components/operatorSelect';


interface AddMaintenanceOpDialogProps {
    equipment: string;
    isOpen: boolean;
    onOperationAdded: () => void;
    onClose: () => void;
}

export default function AddMaintenanceOpDialog({
    equipment,
    isOpen,
    onOperationAdded,
    onClose,
}: AddMaintenanceOpDialogProps) {

    const [operationType, setOperationType] = useState<MaintenanceOperationType | null>(null);
    const [comment, setComment] = useState('');
    const [operator, setOperator] = useState('');


    useEffect(() => {
        if (!isOpen) return;
        setOperationType(null);
        setComment('');
        setOperator('');
    }, [isOpen]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await postMaintenanceOperation({
            equipment,
            operationType: operationType!,
            comment,
            operator,
        });
        onOperationAdded();
        onClose();
    }


    return (
        <ModalDialog
            isOpen={isOpen}
            frameClassName='modal-frame--operation-dialog'
            contentClassName='modal-content--tablet'
            onCancel={onClose}
        >
            <form
                onSubmit={handleSubmit}
            >
                <div className='dialog-form__field--with-margins'>
                    <label>Operation type</label>
                    <OperationType
                        operationType={operationType}
                        onOperationTypeChange={setOperationType}
                    />
                </div>
                <div>
                    <label>Comment</label>
                    <textarea
                        className='dialog-form__input'
                        rows={3}
                        value={comment}
                        required
                        onChange={(e) => setComment(e.target.value)}
                    />
                </div>
                <div className='dialog-form__field--with-margins'>
                    <label>Operator</label>
                    <OperatorSelect
                        className='dialog-form__input'
                        operatorInitials={operator}
                        required
                        onChange={(operatorInitials) => setOperator(operatorInitials)}
                    />
                </div>

                <div className='dialog--button-bar'>
                    <button className='button--large' type='submit'>Submit</button>
                </div>
            </form>
        </ModalDialog>
    )
}


interface OperationTypeProps {
    operationType: MaintenanceOperationType | null;
    onOperationTypeChange: (operationType: MaintenanceOperationType) => void;
}
function OperationType({
    operationType,
    onOperationTypeChange,
}: OperationTypeProps) {
    return (
        <fieldset className='dialog-form__fieldset'>
            {MaintenanceOperationTypes.map(it => (
                <label key={it}>
                    <input
                        type='radio'
                        name='operationType'
                        value={it}
                        required
                        checked={it === operationType}
                        onChange={() => onOperationTypeChange(it)}
                    />
                    {MaintenanceOperationTypeLabels[it]}
                </label>
            ))}
        </fieldset>
    );
}
