import { useState } from 'react';
import { CmmReport, CmmReportHeader } from '../../../shared/types/cmmReport';
import { sortByDesc } from '../../../shared/utils/arrayUtils';
import { fetchCmmReportHeaders, fetchCmmReportsWithFiles as fetchCmmReports } from '../../api/requests';
import importCmmFiles from '../../services/cmm-import/importCmmFiles';
import { ImportFileResult, ImportValue } from '../../services/cmm-import/types';
import { Protocol, RecordDraft } from '../../types/sharedTypeImpl';


interface UseCmmImportProps {
    protocol: Protocol;
    record: RecordDraft;
    setFilesDialogState: (cmmReports: CmmReportHeader[]) => void;
    setPositionsDialogState: (importResults: ImportFileResult[]) => void;
    applyImports: (importValues: ImportValue[], filenames: string[], showToast: boolean) => void;
}

export default function useCmmImport({
    protocol,
    record,
    setFilesDialogState,
    setPositionsDialogState,
    applyImports,
}: UseCmmImportProps) {

    const [isAutoImportClicked, setIsAutoImportClicked] = useState(false);
    const [isManualImportClicked, setIsManualImportClicked] = useState(false);


    const handleAutoImportClick = async () => {
        if (isAutoImportClicked || isManualImportClicked) return;
        try {
            setIsAutoImportClicked(true);
            if (!record.orderNumber) {
                alert('Record must have order number to import automatically');
                return;
            }

            const cmmReports = await fetchCmmReports(record.orderNumber);
            if (cmmReports.length === 0) {
                alert(`No CMM reports found (looking for files in sharepoint that begin with "${record.orderNumber}")`);
                return;
            }

            const filteredByPartCount = cmmReports.filter(it => containsToken(it.name, record.partCount.toString()));
            const reportsFinal = filteredByPartCount.length > 0 ? filteredByPartCount : cmmReports;
            sortByDesc(reportsFinal, it => it.lastModified);

            const importedWithoutDialog = await attemptImportWithoutDialog(reportsFinal);
            if (!importedWithoutDialog) {
                if (cmmReports.length > 1) {
                    setFilesDialogState(reportsFinal);
                } else {
                    const importResults = await importCmmFiles(protocol, record, [cmmReports[0].file], 'sharepoint');
                    setPositionsDialogState(importResults);
                }
            }
        } catch (error) {
            alert('Failed to fetch CMM reports');
        } finally {
            setIsAutoImportClicked(false);
        }
    }

    const attemptImportWithoutDialog = async (cmmReports: CmmReport[]) => {
        if (cmmReports.length !== 1) {
            return false;
        }

        const importResults = await importCmmFiles(protocol, record, [cmmReports[0].file], 'sharepoint');
        const partCountMatches = record.partCount && containsToken(cmmReports[0].name, record.partCount.toString());
        const hasIssues = !importResults[0].isIncluded || importResults[0].positions?.some(it => !it.isIncluded);
        if (!partCountMatches || hasIssues) {
            return false;
        }

        const cmmReport = cmmReports[0];
        const importResult = importResults[0];
        const importValues: ImportValue[] = importResult.positions!.map(it => ({
            position: it.position,
            value: it.value,
            source: importResult.valueSource!,
            sourceSubType: importResult.valueSourceSubType!,
        }));

        applyImports(importValues, [cmmReport.name], true);
        return true;
    }

    const handleManualImportClick = async () => {
        if (isAutoImportClicked || isManualImportClicked) return;
        try {
            setIsManualImportClicked(true);
            const cmmReports = await fetchCmmReportHeaders();
            sortByDesc(cmmReports, it => it.lastModified);
            setFilesDialogState(cmmReports);
        } catch (error) {
            alert('Failed to fetch CMM reports');
        } finally {
            setIsManualImportClicked(false);
        }
    }

    const containsToken = (str: string, token: string) => {
        // Token must be surrounded by non-word characters, an underscore or start/end of string
        const regex = new RegExp(`(\\W|_|^)${token}(\\W|_|$)`, 'i');
        return regex.test(str);
    }


    return {
        onAutoImportClick: handleAutoImportClick,
        onManualImportClick: handleManualImportClick,
        isAutoImportClicked,
        isManualImportClicked,
    };
}
