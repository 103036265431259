import { Protocol, ProtocolDraft } from '../../types/sharedTypeImpl';
import { formatDatetime } from '../../utils/fieldFormats';
import { readFileAsUint8Array } from '../../utils/readFile';
import OperatorSelect from '../operatorSelect';
import { toleranceStandards } from './generalTolerances';
import ProgramNamesList from './programNamesList';


interface ProtocolMetaFieldsProps {
    protocol: Protocol;
    readOnly?: boolean;
    disabled?: boolean;
    setProtocolField?: (field: keyof Protocol, value: string | string[] | null) => void;
    setPdfData?: (data: Uint8Array) => void;
}

export default function ProtocolMetaFields({
    protocol,
    readOnly,
    disabled,
    setProtocolField,
    setPdfData,
}: ProtocolMetaFieldsProps) {

    const editEnabled = !readOnly && !disabled;
    if (editEnabled && (!setProtocolField || !setPdfData)) {
        throw new Error('setProtocolField and setPdfData must be provided when not readonly or disabled');
    }


    const handlePdfFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;
        setPdfData!(await readFileAsUint8Array(file));
        setProtocolField!('pdfFileName', null);//Reset file name, since the field refers to the path in storage
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setProtocolField!(event.target.name as keyof ProtocolDraft, event.target.value);
    }

    const handleProgramNamesChange = (programNames: string[]) => {
        setProtocolField!('programs', programNames);
    }

    const handleTolerancSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (!event.target.value) {
            return;
        }

        setProtocolField!('generalTolerance', event.target.value);
    }


    return (
        <>
            {editEnabled && <tr>
                <td>Pdf file</td>
                <td>
                    <input
                        type='file'
                        accept='.pdf'
                        onClick={(e) => readOnly ? e.preventDefault() : null}
                        onChange={handlePdfFileChange}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </td>
            </tr>}

            <tr>
                <td>Part number</td>
                <td>
                    <input
                        type='text'
                        name='partName'
                        value={protocol.partName ?? ''}
                        onChange={handleTextChange}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </td>
            </tr>

            <tr>
                <td>Revision</td>
                <td>
                    <input
                        type='text'
                        name='partRevision'
                        value={protocol.partRevision ?? ''}
                        onChange={handleTextChange}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </td>
            </tr>

            <tr>
                <td>Program names</td>
                <td>
                    {editEnabled
                        ? <ProgramNamesList
                            programNames={protocol.programs ?? []}
                            setProgramNames={handleProgramNamesChange}
                            readOnly={readOnly}
                        />
                        : <textarea
                            name='programs'
                            value={protocol.programs.join('\n')}
                            rows={protocol.programs.length}
                            readOnly={readOnly}
                            disabled={disabled}
                        />
                    }
                </td>
            </tr>

            <tr>
                <td>General tolerance</td>
                <td>
                    <select
                        style={{ pointerEvents: readOnly ? 'none' : 'auto' }}
                        value={protocol.generalTolerance ?? ''}
                        onChange={handleTolerancSelect}
                        disabled={disabled}
                    >
                        <option value='' />
                        {toleranceStandards.map(it =>
                            <option key={it} value={it}>{it}</option>
                        )}
                    </select>
                </td>
            </tr>

            <tr>
                <td>Customer</td>
                <td>
                    <input
                        type='text'
                        name='customer'
                        value={protocol.customer ?? ''}
                        onChange={handleTextChange}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </td>
            </tr>

            <tr>
                <td>Created by</td>
                <td>
                    <OperatorSelect
                        operatorInitials={protocol.user ?? ''}
                        onChange={(initials) => setProtocolField!('user', initials)}
                        readOnly={readOnly}
                        disabled={disabled}
                        required
                    />
                </td>
            </tr>

            <tr>
                <td>Comment</td>
                <td>
                    <textarea
                        name='comment'
                        value={protocol.comment ?? ''}
                        rows={3}
                        onChange={handleTextChange}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </td>
            </tr>


            {(!editEnabled && protocol.version) && <tr>
                <td>Protocol version</td>
                <td>
                    <input
                        type='text'
                        value={protocol.version}
                        readOnly={true}
                        disabled={disabled}
                    />
                </td>
            </tr>}

            {(!editEnabled && protocol.createTime) && <tr>
                <td>Protocol create time</td>
                <td>
                    <input
                        type='text'
                        value={formatDatetime(protocol.createTime)}
                        readOnly={true}
                        disabled={disabled}
                    />
                </td>
            </tr >}
        </>
    );
}
