import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CmmReportHeader } from '../../../shared/types/cmmReport';
import { archiveCmmReports, fetchCmmReportFile } from '../../api/requests';
import importCmmFiles, { CMM_FILE_EXTENSIONS } from '../../services/cmm-import/importCmmFiles';
import { ImportFileResult, ImportValue } from '../../services/cmm-import/types';
import { ProtocolFinal, RecordDraft } from '../../types/sharedTypeImpl';
import shouldArchiveCmmReports from '../../utils/shouldArchiveCmmReports';
import CmmFilesDialog from './cmmFilesDialog';
import CmmPositionsDialog from './cmmPositionsDialog';
import useCmmImport from './useCmmImport';


interface CmmImportButtonsProps {
    style?: React.CSSProperties;
    protocol: ProtocolFinal;
    record: RecordDraft;
    setRecord: (record: RecordDraft) => void;
    setIsDirty: (isDirty: boolean) => void;
}

export default function CmmImportButtons({
    style,
    protocol,
    record,
    setRecord,
    setIsDirty,
}: CmmImportButtonsProps) {
    const [filesDialogState, setFilesDialogState] = useState<CmmReportHeader[] | null>(null);
    const [positionsDialogState, setPositionsDialogState] = useState<ImportFileResult[] | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);


    const {
        onAutoImportClick,
        onManualImportClick,
        isAutoImportClicked,
        isManualImportClicked,
    } = useCmmImport({
        protocol,
        record,
        setFilesDialogState,
        setPositionsDialogState,
        applyImports,
    });


    const handleImportLocalClick = () => {
        fileInputRef.current!.click();
    };

    const handleLocalFilesSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files!);
        const importResults = await importCmmFiles(protocol, record, files, 'local');
        setPositionsDialogState(importResults);
    };

    const handleFilesDialogSubmit = async (names: string[]) => {
        try {
            const files = await Promise.all(names.map(fetchCmmReportFile));
            const importResults = await importCmmFiles(protocol, record, files, 'sharepoint');
            setPositionsDialogState(importResults);
            setFilesDialogState(null);
        } catch (error) {
            alert('Failed to fetch CMM reports');
        }
    };

    function applyImports(importValues: ImportValue[], sharePointFiles: string[], showToast: boolean = false) {
        setImportedValues(importValues);
        archiveImportedFiles(sharePointFiles);
        setPositionsDialogState(null);
        if (showToast) {
            toast.success('CMM report imported successfully');
        }
    }

    const setImportedValues = (importValues: ImportValue[]) => {
        const newPositionValues = [...record.positionValues];
        for (const importValue of importValues) {
            const positionIdx = protocol.positions.findIndex(it => it.positionNumber === importValue.position);
            newPositionValues[positionIdx] = {
                value: importValue.value,
                source: importValue.source,
                sourceSubType: importValue.sourceSubType,
            }
        }

        setRecord({ ...record, positionValues: newPositionValues });
        setIsDirty(true);
    };

    const archiveImportedFiles = (sharePointFiles: string[]) => {
        if (sharePointFiles == null || sharePointFiles.length === 0) return;
        if (!shouldArchiveCmmReports()) {
            console.log('Skipped archiving CMM reports: ', sharePointFiles);
            return;
        }

        try {
            archiveCmmReports(sharePointFiles);
        } catch (error) {
            alert('Failed to archive some imported files');
        }
    };


    return (
        <>
            <div style={{ ...style, display: 'flex', flexDirection: 'row', gap: '4px' }}>
                <button
                    type='button'
                    onClick={onAutoImportClick}
                    disabled={isAutoImportClicked}
                >
                    Auto-import
                </button>

                <button
                    type='button'
                    onClick={onManualImportClick}
                    disabled={isManualImportClicked}
                >
                    Manual import
                </button>

                <button
                    type='button'
                    onClick={handleImportLocalClick}
                >
                    Local file import
                </button>
                <input
                    type='file'
                    accept={CMM_FILE_EXTENSIONS.join(',')}
                    multiple
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleLocalFilesSelected}
                    tabIndex={-1}
                />
            </div>

            {filesDialogState && (
                <CmmFilesDialog
                    isOpen={true}
                    cmmReports={filesDialogState}
                    initialFilter={protocol.partName}
                    onCancel={() => setFilesDialogState(null)}
                    onSubmit={handleFilesDialogSubmit}
                />
            )}
            {positionsDialogState && (
                <CmmPositionsDialog
                    isOpen={true}
                    importResult={positionsDialogState}
                    onCancel={() => setPositionsDialogState(null)}
                    onSubmit={applyImports}
                />
            )}
        </>
    );
}
