import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import { ALL_EQUIPMENTS } from '../../../shared/types/oeeRow';
import { mapBy, mapToArrayOfKeyVal, sortBy } from '../../../shared/utils/arrayUtils';
import { dateWithoutTime } from '../../../shared/utils/dateUtils';
import { OeeGraphOptions } from '../../components/oee-graph-controls/oeeGraphOptions';
import useResizeObserver from '../../hooks/useResizeObserver';
import { OeeRow } from '../../types/sharedTypeImpl';
import { getShiftColorDark, getShiftColorLight } from '../../utils/liveGraphColors';


interface HoursGraphProps {
    className?: string;
    shiftTable: OeeRow[];
    liveTable: OeeRow[];
    displayOptions: OeeGraphOptions;
}

export default function HoursGraph({
    className,
    shiftTable,
    liveTable,
    displayOptions,
}: HoursGraphProps) {

    const containerRef = useRef<SVGSVGElement>(null);
    const dimensions = useResizeObserver(containerRef);


    useEffect(() => {
        const mainData = prepareData(shiftTable, displayOptions);
        const targetHours = getTargetHours(liveTable);
        drawGraph(containerRef.current!, mainData, targetHours, displayOptions);
    }, [shiftTable, liveTable, dimensions, displayOptions]);


    return (
        <svg
            className={`${className} live-graphs__graph`}
            ref={containerRef}
        />
    );
}


function drawGraph(container: SVGSVGElement, data: PreparedData[], targetHours: number, displayOptions: OeeGraphOptions) {
    const root = d3.select(container);
    root.html('');
    const rootRect = container.getBoundingClientRect();

    const margins = { top: 20, right: 10, bottom: 20, left: 10 };
    const width = rootRect.width - margins.left - margins.right;
    const height = rootRect.height - margins.top - margins.bottom;

    const xScale = d3.scaleBand()
        .domain(data.map(d => d.label))
        .range([0, width])
        .padding(0.07);
    const yScale = d3.scaleLinear()
        .domain([0, targetHours])
        .range([height, 0]);

    const graph = root.append('g')
        .attr('transform', `translate(${margins.left},${margins.top})`);

    // Append x-axis
    graph.append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(xScale));

    // Append target hours line
    graph.append('line')
        .attr('x1', 0)
        .attr('y1', yScale(targetHours))
        .attr('x2', width)
        .attr('y2', yScale(targetHours))
        .attr('stroke', 'gray')
        .attr('stroke-dasharray', '5,5')
        .attr('stroke-width', 0.5);


    // Append graph bars and text
    const barWidth = xScale.bandwidth() / displayOptions.visibleShifts.length;
    const textSize = Math.min(barWidth / 2, 18);
    data.forEach((day, dayIndex) => {
        day.shifts.forEach((shift, barIndex) => {
            graph.append('rect')
                .attr('x', xScale(day.label)! + barWidth * barIndex)
                .attr('y', yScale(shift.totalHours))
                .attr('height', height - yScale(shift.totalHours))
                .attr('width', barWidth)
                .attr('fill', getShiftColorLight(shift.shift));

            graph.append('rect')
                .attr('x', xScale(day.label)! + barWidth * barIndex)
                .attr('y', yScale(shift.setupHours))
                .attr('height', height - yScale(shift.setupHours))
                .attr('width', barWidth)
                .attr('fill', getShiftColorDark(shift.shift));

            graph.append('text')
                .attr('x', xScale(day.label)! + barWidth * barIndex + barWidth / 2)
                .attr('y', yScale(shift.totalHours) - 5)
                .attr('text-anchor', 'middle')
                .attr('font-size', `${textSize}px`)
                .text(shift.totalText);
            graph.append('text')
                .attr('x', xScale(day.label)! + barWidth * barIndex + barWidth / 2)
                .attr('y', yScale(shift.setupHours) - 5)
                .attr('text-anchor', 'middle')
                .attr('font-size', `${textSize}px`)
                .text(shift.setupText);
        });
    });
}


function prepareData(shiftTable: OeeRow[], displayOptions: OeeGraphOptions): PreparedData[] {
    const allEquipmentsRows = shiftTable
        .filter(it => it.equipment === ALL_EQUIPMENTS)
        .filter(it => displayOptions.visibleShifts.includes(it.shift));
    const mapByDay = mapBy(allEquipmentsRows, it => dateWithoutTime(it.start).getTime());
    const days = mapToArrayOfKeyVal(mapByDay, 'dateMillis', 'shifts') as any[];

    sortBy(days, it => it.dateMillis);
    days.forEach(it => it.label = dateToLabel(new Date(it.dateMillis)));

    days.forEach(day => {
        sortBy(day.shifts, (it: any) => it.start);
        day.shifts = day.shifts.map(convertShift);
    });
    return days;
}

function convertShift(oeeRow: OeeRow): PreparedRow {
    const setupHours = (oeeRow.setupTime ?? 0) / 3_600_000;
    const productionHours = (oeeRow.productionTime ?? 0) / 3_600_000;
    const totalHours = setupHours + productionHours;

    return {
        shift: oeeRow.shift,
        setupHours,
        productionHours,
        totalHours,
        setupText: setupHours.toFixed(0),
        productionText: productionHours.toFixed(0),
        totalText: totalHours.toFixed(0),
    };
}

function getTargetHours(liveTable: OeeRow[]) {
    const uniqueEquipments = new Set(liveTable.map(it => it.equipment));
    const realEquipments = [...uniqueEquipments].filter(it => it !== ALL_EQUIPMENTS);
    return 8 * realEquipments.length;
}

function dateToLabel(date: Date): string {
    // const options = { weekday: 'long', month: 'long', day: '2-digit' };
    const options: Intl.DateTimeFormatOptions = { month: 'long', day: '2-digit' };
    const formatted = new Intl.DateTimeFormat('en-US', options).format(date);
    return formatted;
}



interface PreparedData {
    label: string;
    shifts: PreparedRow[];
}

interface PreparedRow {
    shift: string;
    setupHours: number;
    productionHours: number;
    totalHours: number;
    setupText: string;
    productionText: string;
    totalText: string;
}
