import { DateTime } from 'luxon';


export function now(): Date {
    return new Date();
}

export function dateWithoutTime(dateValue: Date | string): Date {
    const date = new Date(dateValue);
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
}

export function dateTimeFinland(dateString: string): Date {
    const date = DateTime.fromISO(dateString, { zone: 'Europe/Helsinki' });

    if (!date.isValid) {
        throw new Error(`Invalid date: ${dateString}`);
    }

    return date.setZone('UTC').toJSDate();
}

export function dateIsoWithTimezone(date: Date | null): string | null {
    if (date == null) return null;
    return DateTime.fromJSDate(date).setZone('Europe/Helsinki').toISO();
}


export function getWeekday(date: Date): number {
    const luxonDate = DateTime.fromJSDate(date).setZone('Europe/Helsinki');
    return luxonDate.weekday;
}

export function getWeekNumber(date: Date): number {
    const luxonDate = DateTime.fromJSDate(date).setZone('Europe/Helsinki');
    return luxonDate.weekNumber;
}
