import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { fetchDiagnostics, fetchIsAdmin, postServerRestart } from '../../api/requests';
import DiagnosticsTable from './diagnosticsTable';
import RestartFeedbackDialog from './restartFeedbackDialog';


export default function Diagnostics() {

    const [isRestartDialogOpen, setIsRestartDialogOpen] = useState(false);
    const [isRestarting, setIsRestarting] = useState(false);


    const { data: diagnosticsResults } = useQuery({
        queryKey: ['diagnostics'],
        queryFn: fetchDiagnostics,
        refetchInterval: 30_000,
    });

    const { data: isAdmin } = useQuery({
        queryKey: ['isAdmin'],
        queryFn: fetchIsAdmin,
    });


    function handleRestartClicked() {
        setIsRestartDialogOpen(true);
    }

    function handleRestart(userDescription: string) {
        postServerRestart(userDescription, diagnosticsResults);

        setIsRestartDialogOpen(false);
        setIsRestarting(true);
        setTimeout(() => {
            location.reload();
        }, 10_000);
    }


    return (
        <>
            <h1>Server status</h1>
            {!diagnosticsResults
                ? 'Loading...'
                : <DiagnosticsTable diagnosticsResults={diagnosticsResults} />
            }
            {isAdmin &&
                <button
                    onClick={handleRestartClicked}
                    disabled={isRestarting}>
                    Restart server
                </button>}
            {isRestarting && <span> Restarting...</span>}
            <RestartFeedbackDialog
                isOpen={isRestartDialogOpen}
                onCancel={() => setIsRestartDialogOpen(false)}
                onSubmit={handleRestart}
            />
        </>
    )
}