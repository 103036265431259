import { Equipment } from '../../../shared/types/equipment';


interface CustomEquipmentsTableProps {
    style: React.CSSProperties;
    equipments: Equipment[];
    // onIsActiveChange: (operator: Operator, isActive: boolean) => void;
}

export default function CustomEquipmentsTable({
    style,
    equipments,
    // onIsActiveChange,
}: CustomEquipmentsTableProps) {
    return (
        <table style={style}>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Display name</th>
                </tr>
            </thead>
            <tbody>
                {equipments?.map(it =>
                    <tr key={it.id}>
                        <td>{it.id}</td>
                        <td>{it.displayName}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
