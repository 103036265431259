import * as d3 from 'd3';
import { ReportGraphData } from './reportGraphData';


type SVGElement = d3.Selection<SVGSVGElement, unknown, null, undefined>;
// type GElement = d3.Selection<SVGGElement, unknown, null, undefined>;

type GraphDimens = {
    width: number,
    height: number,
    textSize: number,
    margins: { top: number, right: number, bottom: number, left: number },
}
type Graph = d3.Selection<SVGGElement, GraphDimens, null, undefined>;


export function drawGraph(
    container: HTMLDivElement,
    data: ReportGraphData[],
    maxValue?: number,
) {
    const root = createRootElement(container);
    const graph = createGraphElement(root);
    const axes = createAxes(root, graph, data, maxValue);
    drawData(graph, axes, data);
}

function createRootElement(container: HTMLDivElement) {
    const rect = container.getBoundingClientRect();
    const containerWidth = rect.width;
    const containerHeight = rect.height;

    let root = d3.select(container).select('svg') as SVGElement;
    if (root.empty()) {
        root = d3.select(container)
            .append('svg')
            .attr('width', containerWidth)
            .attr('height', containerHeight);
    } else {
        root.html('');
    }

    return root;
}


function createGraphElement(root: SVGElement): Graph {
    const graphMargins = { top: 30, right: 30, bottom: 40, left: 90 };
    const graphWidth = +root.attr('width') - graphMargins.left - graphMargins.right;
    const graphHeight = +root.attr('height') - graphMargins.top - graphMargins.bottom;

    let graph = root.select('g') as Graph;
    if (graph.empty()) {
        graph = root.append('g').attr('transform', `translate(${graphMargins.left}, ${graphMargins.top})`) as Graph;
    }

    const textSize = graphWidth > 1000 ? 40 : 30;
    graph.datum({
        width: graphWidth,
        height: graphHeight,
        margins: graphMargins,
        textSize,
    });

    return graph;
}

function createAxes(
    root: SVGElement,
    graph: Graph,
    data: ReportGraphData[],
    maxValue?: number,
) {
    const { width: graphWidth, height: graphHeight, margins: graphMargins, textSize } = graph.datum();

    const x = d3
        .scaleBand()
        .range([0, graphWidth])
        .domain(data.map(d => getLabel(d)))
        .padding(0.4);
    root.append('g')
        .attr('transform', `translate(${graphMargins.left}, ${graphHeight + graphMargins.top})`)
        .call(d3.axisBottom(x))
        .selectAll('text')
        .style('font-size', textSize + 'px');

    const maxValueFinal = maxValue ?? d3.max(data, d => d.mainValue) ?? 1;
    const y = d3
        .scaleLinear()
        .range([graphHeight, 0])
        .domain([0, maxValueFinal]);
    root.append('g')
        .attr('transform', `translate(${graphMargins.left}, ${graphMargins.top})`)
        .call(d3.axisLeft(y))
        .selectAll('text')
        .style('font-size', textSize + 'px');
    return { x, y };
}

function drawData(
    graph: Graph,
    { x, y }: ReturnType<typeof createAxes>,
    data: ReportGraphData[],
) {
    const { height: graphHeight, textSize } = graph.datum();

    // Append the bar graph
    data.forEach(d => {
        graph.append('rect')
            .attr('x', x(getLabel(d))!)
            .attr('y', y(d.mainValue))
            .attr('width', x.bandwidth())
            .attr('height', graphHeight - y(d.mainValue))
            .attr('fill', d.barColor);
    });

    data.forEach(d => {
        // Append hours text
        if (d.hoursLabel) {
            const defaultY = y(d.mainValue) + 1.1 * textSize;
            const useMinY = !d.scoreLabel;
            const finalY = useMinY ? Math.min(defaultY, graphHeight - 0.3 * textSize) : defaultY;

            graph.append('text')
                .attr('x', x(getLabel(d))! + x.bandwidth() / 2)
                .attr('y', finalY)
                .attr('text-anchor', 'middle')
                .attr('font-family', 'Arial')
                .attr('font-size', textSize + 'px')
                .text(d.hoursLabel);
        }

        // Append percentage text
        if (d.scoreLabel) {
            graph.append('text')
                .attr('x', x(getLabel(d))! + x.bandwidth() / 2)
                .attr('y', y(d.mainValue) - 0.3 * textSize)
                .attr('text-anchor', 'middle')
                .attr('font-family', 'Arial')
                .attr('font-size', textSize + 'px')
                .text(d.scoreLabel);
        }
    });
}

function getLabel(d: ReportGraphData): string {
    return `Week: ${d.weekNumber}`;
}
