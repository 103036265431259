import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortBy } from '../../../shared/utils/arrayUtils';
import { fetchRecordsByProtocol } from '../../api/requests';
import MeasurementsGraph from '../../components/measurement-graph/measurementsGraph';
import { PageTitle } from '../../components/pageTitle';
import ProtocolPdf from '../../components/protocol-pdf/protocolPdf';
import VisualInspectionImage from '../../components/visualInspectionImage';
import useConfirmOnLeave from '../../hooks/useConfirmOnLeave';
import useFetchOnce from '../../hooks/useFetchOnce';
import { RecordDraft } from '../../types/sharedTypeImpl';
import RecordForm from './recordForm';
import useLoadProtocol from './useLoadProtocol';
import useNewOrderWarning from './useNewOrderWarning';
import UseRecordSave from './useRecordSave';
import useSetupInitialRecord from './useSetupInitialRecord';


export default function RecordView() {
    const maxGraphDataPoints = 15;

    const searchParams = new URLSearchParams(window.location.search);
    const recordIdParam = searchParams.get('id');
    const modeParam = searchParams.get('mode');

    const [record, setRecord] = useState<RecordDraft | null>(null);
    const [activePositionIdx, setActivePositionIdx] = useState<number | null>(null);
    const [isDirty, setIsDirty] = useState(false);
    const [isSavedRecord, setIsSavedRecord] = useState(false);
    const isFinalSavedRef = useRef(false);

    const {
        protocol,
        protocolFetch,
        pdfContent,
        pdfFetch,
        positionImages,
    } = useLoadProtocol(searchParams, record);

    const recordFetch = useSetupInitialRecord(setRecord, setIsSavedRecord);
    const activePosition = activePositionIdx != null ? protocol!.positions[activePositionIdx] : null;
    const isViewMode = recordIdParam != null && modeParam !== 'edit';

    const prevRecordsFetch = useFetchOnce({
        queryKey: ['prevRecords', protocol?._id, record?.equipment],
        queryFn: async () => await fetchRecordsByProtocol(protocol!._id!,
            record!.equipment, record!.sessionStart, record!.measurementStart, maxGraphDataPoints),
        enabled: protocol?._id != undefined && record != undefined,
        select: it => sortBy([...it], it => it.measurementStart),
    })

    const {
        isSaving,
        draftSaveError,
        handleDraftSave,
        handleFinalSave,
    } = UseRecordSave({
        record,
        protocol,
        isViewMode,
        isSavedRecord,
        isDirty,
        setIsDirty,
        isFinalSavedRef,
        onFinalSaved: () => handleClose(),
    });

    useNewOrderWarning({ record, prevRecords: prevRecordsFetch.data, isViewMode });

    useConfirmOnLeave(() => isDirty && !isFinalSavedRef.current, !isViewMode);

    const navigate = useNavigate();


    const handleClose = () => {
        navigate('/record-list');
    }


    const isLoading = protocolFetch.isLoading || recordFetch.isLoading;
    const error = protocolFetch.error || recordFetch.error;

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>An error has occurred: {error.message}</div>;
    return (
        <>
            <PageTitle title='Record' />
            <div className='measurement-container'>
                <ProtocolPdf
                    className='measurement-main-pane measurement-pdf-container'
                    isViewMode={true}
                    pdfContent={pdfContent}
                    isPdfLoading={pdfFetch.isLoading}
                    positions={protocol?.positions}
                    positionSelection={activePositionIdx != null ? { positionIdx: activePositionIdx } : null} // Creating a new object on render is probably bad for performance
                />
                <div className='measurement-side-pane measurement-form-pane'>
                    {protocol && record &&
                        <RecordForm
                            className='measurement-form-top'
                            protocol={protocol}
                            record={record}
                            setRecord={setRecord}
                            activePositionIdx={activePositionIdx}
                            setActivePositionIdx={setActivePositionIdx}
                            isDirty={isDirty}
                            setIsDirty={setIsDirty}
                            isSavedRecord={isSavedRecord}
                            isViewMode={isViewMode}
                            isSaving={isSaving}
                            draftSaveError={draftSaveError}
                            onDraftSave={handleDraftSave}
                            onFormSubmit={handleFinalSave}
                            onCloseClicked={handleClose}
                        />
                    }

                    {(activePosition?.type === 'measurement' && prevRecordsFetch.data) &&
                        <MeasurementsGraph
                            className='measurement-form-bottom'
                            protocol={protocol!}
                            currentRecord={record}
                            prevRecords={prevRecordsFetch.data}
                            selectedPositionIdx={activePositionIdx!}
                            openDialogOnClick={true}
                            maxDataPoints={maxGraphDataPoints}
                        />
                    }

                    {activePosition?.type === 'visualInspection' &&
                        <VisualInspectionImage
                            className='measurement-form-bottom'
                            imageData={activePosition ? positionImages?.[activePositionIdx!] : null}
                            isViewMode={true}
                        />
                    }
                </div>
            </div>
        </>
    );
}
