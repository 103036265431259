import { countSpcIssues } from '../../../shared/services/spc/countSpcIssues';
import { Protocol, RecordWithProtocol } from '../../types/sharedTypeImpl';


interface UseNumIssuesProps {
    protocol: Protocol | undefined;
    records: RecordWithProtocol[] | undefined;
    enabledSpcChecks: Map<string, boolean[]> | undefined;
    numLatest: number | null;
}

export function useNumIssues({
    protocol,
    records,
    enabledSpcChecks,
    numLatest,
}: UseNumIssuesProps) {

    if (protocol && records && enabledSpcChecks) {
        return countSpcIssues(protocol, records, enabledSpcChecks, numLatest ?? undefined);
    } else {
        return null;
    }
}
