import { useRef, useState } from 'react';
import Button from '../../components/button';
import NavBar, { NavBarRef } from '../../components/navBar';
import FilterableOperationTable from '../../components/operation-table/filterableOperationTable';
import { PageTitle } from '../../components/pageTitle';
import { Session } from '../../types/sharedTypeImpl';
import MainControlBar from './mainControlBar';
import MaintenanceLogTable, { MaintenanceLogTableRef } from './maintenanceLogTable';
import ProductComment from './productComment';
import SessionsTable, { SessionsTableRef } from './sessionsTable';
import { SelectedEquipment, SelectedPart } from './types';


export default function MachineLog() {

    const [selectedEquipment, setSelectedEquipment] = useState<SelectedEquipment | null>(null);
    const [selectedPart, setSelectedPart] = useState<SelectedPart | null>(null);
    const [selectedSession, setSelectedSession] = useState<Session | null>(null);

    const sessionHistoryTableRef = useRef<SessionsTableRef>(null);
    const navBarRef = useRef<NavBarRef>(null);
    const maintenanceLogTableRef = useRef<MaintenanceLogTableRef>(null);


    function handleOperationSaved() {
        sessionHistoryTableRef.current?.refetchCurrentSessionKpis()
        navBarRef.current?.refetchPageIssues()
    }


    const showCncViews = selectedEquipment == null || selectedEquipment.source === 'mtlinki';
    return (
        <>
            <PageTitle title='Machine Log' />
            <NavBar ref={navBarRef} />
            <div className='content body--tablet'>
                <MainControlBar
                    selectedEquipment={selectedEquipment}
                    setSelectedEquipment={setSelectedEquipment}
                    selectedPart={selectedPart}
                    setSelectedPart={setSelectedPart}
                />
                {showCncViews
                    ? <>
                        <ProductComment selectedPart={selectedPart} />
                        <SessionsTable
                            ref={sessionHistoryTableRef}
                            selectedEquipment={selectedEquipment}
                            selectedPart={selectedPart}
                            selectedSession={selectedSession}
                            setSelectedSession={setSelectedSession}
                        />
                        <FilterableOperationTable
                            session={selectedSession}
                            selectedEquipment={selectedEquipment}
                            onOperationSaved={handleOperationSaved}
                        />
                    </>
                    : <>
                        <Button
                            className='button--large'
                            style={{ marginTop: '16px' }}
                            onClick={() => maintenanceLogTableRef.current?.onAddOperationClicked()}
                            content='Add operation'
                        />
                        <MaintenanceLogTable
                            ref={maintenanceLogTableRef}
                            style={{ marginTop: '8px' }}
                            equipment={selectedEquipment!.id}
                        />
                    </>
                }
            </div>
        </>
    )
}
