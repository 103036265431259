import { v4 as uuidv4 } from 'uuid';
import { PositionDraft, ProtocolDraft, ProtocolFinal, RecordDraft, RecordFinal } from '../types/sharedTypeImpl';


export function protocolToDraft(protocol: ProtocolFinal): ProtocolDraft {
    return {
        ...protocol,
        positions: protocol.positions.map(position => ({
            ...position,
            key: uuidv4(),
        })),
    }
}

export function draftToProtocol(draft: ProtocolDraft): ProtocolFinal {
    const protocol = structuredClone(draft) as ProtocolFinal;
    protocol.positions.forEach(position => {
        delete (position as Partial<PositionDraft>).key;
        delete (position as Partial<PositionDraft>).imageFile;
    });
    return protocol;
}


export function recordToDraft(record: RecordFinal): RecordDraft {
    return {
        //App controlled
        _id: record._id,
        isDraft: true,
        protocolId: record.protocolId,
        protocolPartDisplayName: record.protocolPartDisplayName,
        protocolVersion: record.protocolVersion,
        equipment: record.equipment,
        equipmentDisplayName: record.equipmentDisplayName,
        product: record.product,
        sessionStart: record.sessionStart,
        measurementId: record.measurementId,
        measurementStart: record.measurementStart,
        measurementEnd: record.measurementEnd,

        //User controlled
        orderNumber: record.orderNumber ?? '',
        targetQuantity: record.targetQuantity?.toString() ?? '',
        hasTargetQuantityChanged: false,
        partCount: record.partCount?.toString() ?? '',
        isPartCountEditEnabled: false,
        usedTools: record.usedTools ?? [],
        positionValues: record.positionValues.map(it => it),
        generalVisualInspections: record.generalVisualInspections,
        user: record.user ?? '',
        comment: record.comment ?? '',
    }
}

export function draftToRecord(draft: RecordDraft, numIssues: number): RecordFinal {
    return {
        _id: draft._id,
        isDraft: false,
        protocolId: draft.protocolId,
        protocolPartDisplayName: draft.protocolPartDisplayName,
        protocolVersion: draft.protocolVersion,
        equipment: draft.equipment,
        equipmentDisplayName: draft.equipmentDisplayName,
        product: draft.product,
        sessionStart: draft.sessionStart,
        measurementId: draft.measurementId,
        measurementStart: draft.measurementStart,
        measurementEnd: draft.measurementEnd,
        numIssues,
        orderNumber: draft.orderNumber,
        targetQuantity: draft.targetQuantity ? +draft.targetQuantity : null,
        partCount: +draft.partCount,
        usedTools: draft.usedTools,
        positionValues: draft.positionValues.map(it => it),
        generalVisualInspections: draft.generalVisualInspections,
        user: draft.user,
        comment: draft.comment,
    }
}
