import { MeasurementIntervalState } from '../../types/sharedTypeImpl';


export default function measurementIntervalSort(rows: MeasurementIntervalState[]) {
    rows.sort(measurementIntervalCompare);
}

function measurementIntervalCompare(a: MeasurementIntervalState, b: MeasurementIntervalState) {
    return withoutProductToBottom(a, b)
        // || withoutPartCountToBottom(a, b)
        || setupStatusToBottom(a, b)
        || helpMeStatusToTop(a, b)
        || fixInProgressStatusToBottom(a, b)
        || earlyDisurptionStatusesToTop(a, b)
        || earlyintervalPredictionToTop(a, b);
}

function withoutProductToBottom(a: MeasurementIntervalState, b: MeasurementIntervalState) {
    const aDefined = a.product != undefined && a.product != null;
    const bDefined = b.product != undefined && b.product != null;
    return +bDefined - +aDefined;
}

function setupStatusToBottom(a: MeasurementIntervalState, b: MeasurementIntervalState) {
    return +(b.isSetupComplete ?? false) - +(a.isSetupComplete ?? false);
}

function helpMeStatusToTop(a: MeasurementIntervalState, b: MeasurementIntervalState) {
    const aHasDisruption = a.hasDisruption ?? false;
    const bHasDisruption = b.hasDisruption ?? false;
    const aFixInProgress = a.fixInProgress ?? false;
    const bFixInProgress = b.fixInProgress ?? false;
    return +(bHasDisruption && !bFixInProgress) - +(aHasDisruption && !aFixInProgress);
}

function fixInProgressStatusToBottom(a: MeasurementIntervalState, b: MeasurementIntervalState) {
    return +(a.fixInProgress ?? 0) - +(b.fixInProgress ?? 0);
}

function earlyDisurptionStatusesToTop(a: MeasurementIntervalState, b: MeasurementIntervalState) {
    const aDefined = a.disruptionStatusStart != undefined && a.disruptionStatusStart != null;
    const bDefined = b.disruptionStatusStart != undefined && b.disruptionStatusStart != null;
    if (!aDefined || !bDefined) {
        return +bDefined - +aDefined;
    }

    const aDate = new Date(a.disruptionStatusStart!);
    const bDate = new Date(b.disruptionStatusStart!);
    const ret = +(aDate > bDate) || -(aDate < bDate);

    return ret;
}

function earlyintervalPredictionToTop(a: MeasurementIntervalState, b: MeasurementIntervalState) {
    const aDefined = a.intervalPrediction != undefined && a.intervalPrediction != null;
    const bDefined = b.intervalPrediction != undefined && b.intervalPrediction != null;
    if (!aDefined || !bDefined) {
        return +bDefined - +aDefined;
    }

    const aDate = new Date(a.intervalPrediction!);
    const bDate = new Date(b.intervalPrediction!);
    const ret = +(aDate > bDate) || -(aDate < bDate);

    return ret;
}
