import { useMemo } from 'react';
import ModalDialog from '../../components/modalDialog';
import ProtocolPdf from '../../components/protocol-pdf/protocolPdf';
import { useFetchProtocolPdfOnce } from '../../hooks/fetchHooks';
import { Protocol } from '../../types/sharedTypeImpl';


interface PrtocolPdfDialogProps {
    protocol: Protocol | undefined;
    selectedPositionIdx: number | null;
    show: boolean;
    onClose: () => void;
}

export default function ProtocolPdfDialog({
    protocol,
    selectedPositionIdx,
    show,
    onClose
}: PrtocolPdfDialogProps) {

    const pdfFetch = useFetchProtocolPdfOnce(protocol?._id);


    const positionSelection = useMemo(() => {
        return selectedPositionIdx != null
            ? { positionIdx: selectedPositionIdx }
            : null;
    }, [selectedPositionIdx]);

    return (
        <ModalDialog
            isOpen={show}
            frameStyle={{ width: '80%', height: '90%' }}
            contentStyle={{ height: '100%' }}
            cancelOnOutsideClick={true}
            onCancel={onClose}
        >
            <ProtocolPdf
                className='measurement-pdf-container'
                style={{ width: '100%', height: '70vh' }}
                isViewMode={true}
                positions={protocol?.positions}
                positionSelection={positionSelection}
                showAllPositions={false}
                pdfContent={pdfFetch.data ?? null}
                isPdfLoading={pdfFetch.isLoading}
            />
        </ModalDialog>
    );
}
