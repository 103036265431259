import { ProtocolFinal, RecordDraft } from '../../types/sharedTypeImpl';
import importIssuesAnalysis from './importIssuesAnalysis';
import readCmmFile from './readCmmFile';
import { FileSource, ImportFileResult, ReadFileResult } from './types';


export const CMM_FILE_EXTENSIONS = ['.csv', 'xlsx', '.xlsm', '.xls'];


export default async function importCmmFiles(
    protocol: ProtocolFinal,
    record: RecordDraft,
    files: File[],
    fileSource: FileSource,
): Promise<ImportFileResult[]> {
    // Read all files
    const readResults: ReadFileResult[] = [];
    for (const file of files) {
        const readResult = await readCmmFile(file);
        readResults.push(readResult);
    }

    // Create the result object
    const finalResults: ImportFileResult[] = [];
    for (const [index, readResult] of readResults.entries()) {
        // Result when reading the file failed
        if (!readResult.success) {
            finalResults.push({
                filename: files[index].name,
                fileSource,
                valueSource: readResult.source,
                valueSourceSubType: readResult.sourceSubType,
                errorMessage: readResult.errorMessage,
                isImportable: false,
                isIncluded: false,
            });
            continue;
        }

        // Find any issues with the measurements
        const issueResults = importIssuesAnalysis(protocol, record, readResults[index].measurements!);

        // Create the result object
        finalResults.push({
            filename: files[index].name,
            fileSource,
            valueSource: readResult.source,
            valueSourceSubType: readResult.sourceSubType,
            isImportable: true,
            isIncluded: true,
            positions: issueResults,
        });
    }

    return finalResults;
}
