import { useEffect, useState } from 'react';
import { maxBy } from '../../../shared/utils/arrayUtils';
import { RecordDraft, RecordWithProtocol } from '../../types/sharedTypeImpl';


interface UseNewOrderWarningProps {
    record: RecordDraft | undefined | null;
    prevRecords: RecordWithProtocol[] | undefined;
    isViewMode: boolean;
}

export default function useNewOrderWarning({
    record,
    prevRecords,
    isViewMode,
}: UseNewOrderWarningProps) {
    const [haveShownWarning, setHaveShownWarning] = useState(false);

    useEffect(() => {
        if (!haveShownWarning && !isViewMode && record && prevRecords) {
            const latestRecord = maxBy(prevRecords, it => +it.measurementStart!);
            if (latestRecord?.partCount && record.partCount && latestRecord.partCount > record.partCount) {
                setHaveShownWarning(true);
                alert('The part count is lower than the previous record\'s. Validate order number and target quantity.');
            }
        }
    }, [isViewMode, record, prevRecords, haveShownWarning]);
}
