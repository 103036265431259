import { RefObject, useEffect, useState } from 'react';


interface Dimensions {
    width: number;
    height: number;
    x: number;
    y: number;
    top: number;
    right: number;
    bottom: number;
    left: number;
}

export default function useResizeObserver(ref: RefObject<HTMLElement | SVGSVGElement>): Dimensions | null {
    const [dimensions, setDimensions] = useState<Dimensions | null>(null);

    useEffect(() => {
        const observeTarget = ref.current!;
        const resizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                setDimensions(entry.contentRect);
            });
        });

        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);

    return dimensions;
}
