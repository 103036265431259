import { useEffect, useState } from 'react';
import { postBatchDetails } from '../../api/requests';
import ModalDialog from '../../components/modalDialog';
import { MeasurementIntervalState } from '../../types/sharedTypeImpl';


interface BatchDetailsDialogProps {
    measureIntervalRow: MeasurementIntervalState;
    onClose: () => void;
}

export default function BatchDetailsDialog({
    measureIntervalRow,
    onClose,
}: BatchDetailsDialogProps) {

    const [targetQuantity, setTargetQuantity] = useState<string>('');
    const [orderNumber, setOrderNumber] = useState<string>(''.toString());


    useEffect(() => {
        setTargetQuantity((measureIntervalRow.targetQuantity ?? '').toString())
        setOrderNumber((measureIntervalRow.orderNumber ?? '').toString())
    }, [measureIntervalRow]);

    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    async function handleSubmit() {
        if (!measureIntervalRow) return;

        const targetQuantityParsed = targetQuantity ? parseInt(targetQuantity) : null;
        if (!targetQuantityParsed) {
            alert('Invalid target quantity');
            return;
        }

        const result = await upload(measureIntervalRow, orderNumber, targetQuantityParsed);
        if (result) {
            // onValuesChanged();
        }
        onClose();
    }

    async function upload(
        row: MeasurementIntervalState,
        orderNumber: string,
        targetQuantity: number | null,
    ): Promise<boolean> {
        try {
            await postBatchDetails(
                row.equipment,
                row.product!,
                row.sessionStart!,
                orderNumber,
                targetQuantity,
            );
            return true;
        } catch (error) {
            alert(`Setting values failed. ${(error as Error).message}`);
            console.log(error);
            return false;
        }
    }


    if (!measureIntervalRow) return null;

    const equipmentAndProduct = [measureIntervalRow.displayName, measureIntervalRow.product].filter(it => it != null).join(' - ');
    return (
        <ModalDialog
            frameStyle={{ minWidth: '360px' }}
            isOpen={true}
            onCancel={onClose}
        >
            <div>{equipmentAndProduct}</div>

            <div className='dialog-form__field--with-margins' >
                <div>Order number</div>
                <input
                    className='dialog-form__input'
                    type={'text'}
                    value={orderNumber}
                    onChange={e => setOrderNumber(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
            </div>

            <div className='dialog-form__field--with-margins' >
                <div>Target quantity</div>
                <input
                    className='dialog-form__input'
                    type={'number'}
                    value={targetQuantity}
                    onChange={e => setTargetQuantity(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
            </div>

            <div className='dialog--button-bar'>
                <button onClick={onClose}>Cancel</button>
                <button onClick={handleSubmit}>Submit</button>
            </div>
        </ModalDialog >
    )
}
