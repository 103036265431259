import { MainData } from '../components/equipment-oee-graph/transformData';
import { OeeGraphOptions } from '../components/oee-graph-controls/oeeGraphOptions';
import { Scraps } from '../pages/live-graphs/graphValueType';


export const MORNING_LIGHT = 'lightblue';
export const MORNING_DARK = 'blue';
export const EVENING_LIGHT = '#e7d3b6';
export const EVENING_DARK = 'rgb(182, 139, 0)';
export const NIGHT_LIGHT = '#DDDDDD';
export const NIGHT_DARK = 'gray';

export const LIVE_GRAPH_GOOD = '#36c22f';
export const LIVE_GRAPH_MED = '#dac400';
export const LIVE_GRAPH_BAD = '#D64550';
export const LIVE_GRAPH_NONE = '#999D';
export const LIVE_GRAPH_SETUP = '#0065e8';


export function getShiftColorLight(shift: string): string {
    if (shift === 'morning') {
        return MORNING_LIGHT;
    } else if (shift === 'evening') {
        return EVENING_LIGHT;
    } else {
        return NIGHT_LIGHT;
    }
}

export function getShiftColorDark(shift: string): string {
    if (shift === 'morning') {
        return MORNING_DARK;
    } else if (shift === 'evening') {
        return EVENING_DARK;
    } else {
        return NIGHT_DARK;
    }
}

export function getGraphColor(
    dataPoint: MainData,
    displayOptions: OeeGraphOptions,
): string {
    return !dataPoint.isCurrentProduct || dataPoint.isDisconnect ? LIVE_GRAPH_NONE
        : dataPoint.isSetup ? LIVE_GRAPH_SETUP
            : displayOptions.valueType === Scraps ? (dataPoint.rollingAverageValue > 0 ? LIVE_GRAPH_BAD : LIVE_GRAPH_GOOD)
                : getGraphColorSimple(!!dataPoint.weight, dataPoint.rollingAverageValue);
}

export function getGraphColorSimple(inActiveState: boolean, availability: number): string {
    if (inActiveState === false) {
        return LIVE_GRAPH_NONE;
    } else if (availability >= 0.95) {
        return LIVE_GRAPH_GOOD;
    } else if (availability >= 0.85) {
        return weightedColorAverage(LIVE_GRAPH_GOOD, LIVE_GRAPH_MED, availability - 0.85, 0.95 - availability);
    } else if (availability >= 0.65) {
        return weightedColorAverage(LIVE_GRAPH_MED, LIVE_GRAPH_BAD, availability - 0.65, 0.85 - availability);
    } else {
        return LIVE_GRAPH_BAD;
    }
}

function weightedColorAverage(hexColor1: string, hexColor2: string, weight1: number, weight2: number): string {
    const color1RGB = hexToRgb(hexColor1);
    const color2RGB = hexToRgb(hexColor2);

    const avgR = Math.round((color1RGB.r * weight1 + color2RGB.r * weight2) / (weight1 + weight2));
    const avgG = Math.round((color1RGB.g * weight1 + color2RGB.g * weight2) / (weight1 + weight2));
    const avgB = Math.round((color1RGB.b * weight1 + color2RGB.b * weight2) / (weight1 + weight2));

    return rgbToHex(avgR, avgG, avgB);
}

function hexToRgb(hex: string): { r: number, g: number, b: number } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) {
        throw new Error(`Invalid hex color: ${hex}`);
    }

    return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    };
}

function rgbToHex(r: number, g: number, b: number): string {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}
