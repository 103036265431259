import { Position } from '../../../shared/types/protocol';
import { RecordPosition } from '../../../shared/types/record';
import { Protocol } from '../../types/sharedTypeImpl';


export default function getWayOutOfSpecValues(
    protocol: Protocol,
    positionValues: RecordPosition[],
): { position: Position, positionValue: RecordPosition }[] {
    const out = [];
    for (const [i, position] of protocol.positions.entries()) {
        if (position.type !== 'measurement') continue;
        const { nominal, lowerTolerance, upperTolerance } = position;

        const positionValue = positionValues[i];
        const value = +positionValue.value;
        if (!value) continue;

        let isWayOutOfSpec = false;
        if (+lowerTolerance! || +upperTolerance!) {
            const avgTolerance = (Math.abs(+lowerTolerance!) + +upperTolerance!) / 2;
            const toleranceRatio = Math.max(
                Math.abs(value - +nominal!) / Math.max(Math.abs(+lowerTolerance!), avgTolerance),
                Math.abs(value - +nominal!) / Math.max(Math.abs(+upperTolerance!), avgTolerance)
            );

            isWayOutOfSpec = toleranceRatio > 10;
        } else {
            const nominalRatio = Math.abs(value - +nominal!) / +nominal!;
            isWayOutOfSpec = nominalRatio > 0.5;
        }

        if (isWayOutOfSpec) {
            out.push({
                position,
                positionValue,
            });
        }
    }
    return out;
}
