import { Point } from '../../../shared/types/mathTypes';
import { Marker } from '../../../shared/types/protocol';
import PositionSelection from '../../types/positionSelection';
import { ProtocolDraft } from '../../types/sharedTypeImpl';
import { ContextMenu, MenuItem } from '../contextMenu';


interface FormPositionMenuProps {
    protocol: ProtocolDraft;
    positionSelection: PositionSelection;
    screenCoords: Point;
    removePosition: (index: number) => void;
    removeMarker: (positionIdx: number, markerIdx: number) => void;
    setMarkerField: (positionIdx: number, markerIdx: number, field: keyof Marker, value: string | number) => void;
    onClose: () => void;
}

export default function FormPositionMenu({
    protocol,
    positionSelection,
    screenCoords,
    removePosition,
    removeMarker,
    setMarkerField,
    onClose,
}: FormPositionMenuProps) {

    const positionIdx = positionSelection.positionIdx;
    const position = protocol.positions[positionIdx];
    const selectedMarkerIdx = positionSelection.markerIdx;
    const selectedMarker = selectedMarkerIdx != null ? position.markers[selectedMarkerIdx] : undefined;
    const defaultMarkerIdx = selectedMarkerIdx ?? 0;
    const defaultMarker = position.markers[defaultMarkerIdx];
    const isCircleMarker = defaultMarker.x2 == null;

    const menuItems: MenuItem[] = [
        {
            text: 'Set description',
            callback: () => handleSelectSetDescription()
        },
        {
            text: `Delete ${selectedMarker == null ? 'position' : 'marker'}`,
            callback: () => handleDeletePosition()
        },
        {
            text: 'Increase marker size',
            isEnabled: isCircleMarker,
            callback: () => adjustMarkerSize(true),
        },
        {
            text: 'Decrease marker size',
            isEnabled: isCircleMarker,
            callback: () => adjustMarkerSize(false),
        },
    ];


    const handleSelectSetDescription = () => {
        const currentDescription = defaultMarker.text ?? '';
        const description = prompt('Enter a description', currentDescription);
        if (description != null) {
            setMarkerField(positionIdx, defaultMarkerIdx, 'text', description);
        }
    };

    const handleDeletePosition = () => {
        if (selectedMarker == null) {
            const positionNumber = position.positionNumber;
            if (confirm(`Delete position ${positionNumber}?`)) {
                removePosition(positionIdx);
            }
        } else {
            if (confirm(`Delete marker ${selectedMarkerIdx! + 1}?`)) {
                removeMarker(positionIdx, selectedMarkerIdx!);
            }
        }
    };

    const adjustMarkerSize = (increase: boolean) => {
        const sizeFactor = defaultMarker.sizeFactor ?? 0;
        const newSizeFactor = sizeFactor + (increase ? 1 : -1);
        setMarkerField(positionIdx, defaultMarkerIdx, 'sizeFactor', newSizeFactor);
    };

    return (
        <ContextMenu
            isOpen={true}
            position={screenCoords}
            items={menuItems}
            close={onClose}
        />
    );
}
