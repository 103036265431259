import { MeasurementIntervalStateBase } from '../../types/measurementIntervalState.js';

type MeasurementIntervalState = MeasurementIntervalStateBase<any>;


export function requiresMeasuring(row: MeasurementIntervalState): boolean {
    if (row.intervalPrediction == null || row.targetType == null) {
        return false;
    }

    if (row.targetType === 'setup-confirmation') {
        return true;
    } else if (row.targetType === 'part-count') {
        return row.measureIntervalCount! >= row.measureIntervalTarget!;
    } else {
        return +row.intervalPrediction < +new Date();
    }
}
