import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { SpcState } from '../../shared/types/spcState';
import { fetchSpcStates } from '../api/requests';
import NavBar from '../components/navBar';
import { PageTitle } from '../components/pageTitle';
import { encodeUrl } from '../utils/fetchRequests';


export default function SpcList() {

    const { data: spcStates, error, isLoading } = useQuery({
        queryKey: ['spc/current'],
        queryFn: async () => await fetchSpcStates(),
    });


    return (
        <>
            <PageTitle title='Spc' />
            <NavBar />
            <div className='content' style={{ maxWidth: '1280px' }}>
                {isLoading && <div>Loading...</div>}
                {error && <div>Error: {error.message}</div>}
                {spcStates && <SpcTable spcStates={spcStates} />}
            </div>
        </>
    );
}


function SpcTable({
    spcStates,
}: {
    spcStates: SpcState[]
}) {
    return (
        <table>
            <thead>
                <tr>
                    <th>Equipment</th>
                    <th>Part number</th>
                    <th>Order number</th>
                    <th style={{ maxWidth: '80px' }}>Measurements<br />order / batch</th>
                    <th style={{ maxWidth: '70px' }}>Issues<br />enabled / all</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {spcStates.map(spcState => (
                    <tr key={spcState.equipment}>
                        <td className='textAlignLeft' style={{ paddingLeft: '60px'}}>
                            <Link className='link-button--non-intrusive' to={`/machine-log#equipment=${spcState.equipment}`}>
                                {spcState.equipmentDisplayName}
                            </Link>
                        </td>
                        <td className='textAlignLeft' style={{ paddingLeft: '60px' }}>
                            {spcState.partDisplayName}
                        </td>
                        <td>{spcState.orderNumber}</td>
                        <td>{spcState.measurementsInOrder} / {spcState.measurementsInBatch}</td>
                        <td>{spcState.enabledIssuesCount} / {spcState.allIssuesCount}</td>
                        <td>
                            {spcState.measurementsInBatch
                                ? <Link
                                    className='a__button'
                                    to={encodeUrl('/spc-details', { equipment: spcState.equipment, product: spcState.product })}
                                >
                                    View
                                </Link>
                                : <a className='a__button, a__button--disabled' href='#'>View</a>
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
