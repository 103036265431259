import { Point } from '../../../shared/types/mathTypes';
import { ContextMenu, MenuItem } from '../../components/contextMenu';
import { Protocol } from '../../types/sharedTypeImpl';


export interface ProtocolContextMenuState {
    protocol: Protocol;
    screenCoords: Point;
}


interface ProtocolContextMenuProps {
    protocol: Protocol;
    screenCoords: Point;
    onEnableSelected: (protocol: Protocol) => void;
    onDisableSelected: (protocol: Protocol) => void;
    onCopySelected: (protocol: Protocol) => void;
    onClose: () => void;
}

export default function ProtocolContextMenu({
    protocol,
    screenCoords,
    onEnableSelected,
    onDisableSelected,
    onCopySelected,
    onClose,
}: ProtocolContextMenuProps) {

    const menuItems: MenuItem[] = [
        {
            text: 'Enable',
            isEnabled: !!protocol.isDisabled,
            callback: () => onEnableSelected(protocol),
        },
        {
            text: 'Disable',
            isEnabled: !protocol.isDisabled,
            callback: () => onDisableSelected(protocol),
        },
        {
            text: 'Copy',
            callback: () => onCopySelected(protocol),
        },
    ];

    return (
        <ContextMenu
            isOpen={true}
            position={screenCoords}
            items={menuItems}
            close={onClose}
        />
    );
}
