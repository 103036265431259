import { useEffect, useRef } from 'react';
import { sumOf } from '../../../shared/utils/arrayUtils';
import { Operation } from '../../types/sharedTypeImpl';
import { Week } from '../../types/week';
import { LIVE_GRAPH_MED } from '../../utils/liveGraphColors';
import { drawGraph } from './drawGraph';
import { ReportGraphData } from './reportGraphData';


interface ScrapsTimeGraphProps {
    style?: React.CSSProperties;
    operations: Operation[];
    weeks: Week[];
}

export default function ScrapsTimeGraph({
    style,
    operations,
    weeks,
}: ScrapsTimeGraphProps) {
    const graphContainerRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const transformedData = transformData(operations, weeks);
        drawGraph(graphContainerRef.current!, transformedData);
    }, [operations, weeks]);


    function transformData(operations: Operation[], weeks: Week[]): ReportGraphData[] {
        const data: ReportGraphData[] = [];
        for (const week of weeks) {
            const operationsWeek = operations
                .filter(it => it.scrapCloseForm?.time != null && it.scrapCloseForm.timeSpent != null)
                .filter(it => it.scrapCloseForm!.time! >= week.start && it.scrapCloseForm!.time! < week.end);

            const timeSpentTotal = sumOf(operationsWeek, it => it.scrapCloseForm!.timeSpent! ?? 0) / 3600_000;
            data.push({
                ...week,
                hoursLabel: timeSpentTotal.toFixed(0) + 'h',
                mainValue: timeSpentTotal,
                barColor: LIVE_GRAPH_MED,
            });
        }
        return data;
    }


    return (
        <div style={{ ...style, height: '100%' }}
            ref={graphContainerRef}
        />
    )
}