

export const toleranceStandards = [
    'ISO 2768-f',
    'ISO 2768-m',
    'ISO 2768-c',
];

export interface Tolerances {
    upperTolerance: string;
    lowerTolerance: string;
}

export function getTolerances(nominal: string, standard: string, isChamfer: boolean): Tolerances | null {
    const nominalAsFloat = parseFloat(nominal);
    if (nominalAsFloat == null || Number.isNaN(nominalAsFloat)) return null;

    const ranges = isChamfer ? chamferToleranceRanges : defaultToleranceRanges;
    const toleranceSets = isChamfer ? chamferTolerances : defaultTolerances;
    const tolerances = toleranceSets.find(it => it.standard === standard)?.tolerances;
    if (tolerances == null) throw new Error('Invalid standard: ' + standard);

    //Edge values belong to the smaller tolerance range
    for (const [idx, range] of ranges.entries()) {
        if (nominalAsFloat < range[0]) break;
        if (range[1] != null && nominalAsFloat > range[1]) continue;
        return {
            upperTolerance: (tolerances[idx]!).toFixed(2),
            lowerTolerance: (-tolerances[idx]!).toFixed(2),
        };
    }
    return null;
}


const defaultToleranceRanges: NominalRange[] = [
    [0.5, 3],
    [3, 6],
    [6, 30],
    [30, 120],
    [120, 400],
    [400, 1000],
    [1000, 2000],
    [2000, 4000],
];

const chamferToleranceRanges: NominalRange[] = [
    [0.5, 3],
    [3, 6],
    [6, null],
];

const defaultTolerances: DefaultToleranceSet[] = [
    {
        standard: 'ISO 2768-f',
        tolerances: [0.05, 0.05, 0.1, 0.15, 0.2, 0.3, 0.5, null],
    },
    {
        standard: 'ISO 2768-m',
        tolerances: [0.1, 0.1, 0.2, 0.3, 0.5, 0.8, 1.2, 2],
    },
    {
        standard: 'ISO 2768-c',
        tolerances: [0.2, 0.3, 0.5, 0.8, 1.2, 2, 3, 4],
    },
];

const chamferTolerances: ChamferToleranceSet[] = [
    {
        standard: 'ISO 2768-f',
        tolerances: [0.2, 0.5, 1],
    },
    {
        standard: 'ISO 2768-m',
        tolerances: [0.2, 0.5, 1],
    },
    {
        standard: 'ISO 2768-c',
        tolerances: [0.4, 1, 2],
    },
];


interface DefaultToleranceSet {
    standard: string;
    tolerances: [number, number, number, number, number, number, number, number | null,];
}
interface ChamferToleranceSet {
    standard: string;
    tolerances: [number, number, number,];
}
type NominalRange = [number, number | null];
