import { useQuery } from '@tanstack/react-query';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { MaintenanceOperationTypeLabels } from '../../../shared/types/maintenanceOperation';
import { sortByDesc } from '../../../shared/utils/arrayUtils';
import { fetchMaintenceOperations } from '../../api/requests';
import { formatDatetime } from '../../utils/fieldFormats';
import AddMaintenanceOpDialog from './addMaintenanceOpDialog';


export interface MaintenanceLogTableRef {
    onAddOperationClicked: () => void;
}

interface MaintenanceLogTableProps {
    equipment: string,
    style?: React.CSSProperties,
}

const MaintenanceLogTable = forwardRef<MaintenanceLogTableRef, MaintenanceLogTableProps>(({
    equipment,
    style,
}, ref) => {
    const [isAddOperationDialogOpen, setIsAddOperationDialogOpen] = useState(false);

    const { data: maintenanceOperations, isLoading, error, refetch } = useQuery({
        queryKey: ['maintenance', equipment],
        queryFn: async () => fetchMaintenceOperations(equipment),
        select: maintenanceOperations => sortByDesc([...maintenanceOperations], it => it.time),
    });


    useImperativeHandle(ref, () => ({
        onAddOperationClicked: handleAddOperationClicked,
    }));

    function handleAddOperationClicked() {
        if (!isAddOperationDialogOpen) {
            setIsAddOperationDialogOpen(true);
        }
    }

    return (<>
        {isLoading && <p>Loading...</p>}
        {error && <p>Error retrieving data</p>}
        {maintenanceOperations &&
            <table style={style}>
                <thead>
                    <tr>
                        <th>Operation type</th>
                        <th>Comment</th>
                        <th>Date / time</th>
                        <th>Operator</th>
                    </tr>
                </thead>
                <tbody>
                    {maintenanceOperations.map(operation => (
                        <tr key={operation._id}>
                            <td>{
                                operation.operationType
                                    ? MaintenanceOperationTypeLabels[operation.operationType]
                                    : ''
                            }</td>
                            <td>{operation.comment}</td>
                            <td>{formatDatetime(operation.time)}</td>
                            <td>{operation.operator?.toUpperCase()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        }

        <AddMaintenanceOpDialog
            equipment={equipment}
            isOpen={isAddOperationDialogOpen}
            onOperationAdded={refetch}
            onClose={() => setIsAddOperationDialogOpen(false)}
        />
    </>)
});
export default MaintenanceLogTable;
