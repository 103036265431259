import { useEffect, useRef } from 'react';
import { OeeRow } from '../../types/sharedTypeImpl';
import { Week } from '../../types/week';
import { getGraphColorSimple } from '../../utils/liveGraphColors';
import { OeeGraphOptions } from '../oee-graph-controls/oeeGraphOptions';
import { drawGraph } from './drawGraph';
import { ReportGraphData } from './reportGraphData';


interface AvailabilityReportGraphProps {
    style?: React.CSSProperties;
    oeeData: OeeRow[];
    oeeGraphOptions: OeeGraphOptions;
    weeks: Week[];
}

export default function AvailabilityReportGraph({
    style,
    oeeData,
    oeeGraphOptions,
    weeks,
}: AvailabilityReportGraphProps) {

    const graphContainerRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const transformedData = transformData(oeeData, oeeGraphOptions, weeks);
        drawGraph(graphContainerRef.current!, transformedData, 1.0);
    }, [oeeData, oeeGraphOptions, weeks]);


    function transformData(oeeData: OeeRow[], oeeGraphOptions: OeeGraphOptions, weeks: Week[]): ReportGraphData[] {
        const oeeDataFiltered = oeeData
            .filter(it => oeeGraphOptions.visibleShifts.includes(it.shift))
            .filter(it => !oeeGraphOptions.excludeWeekends || it.shiftName !== 'W');

        const aggregatedPerWeek: ReportGraphData[] = [];
        for (const week of weeks) {
            const oeeDataWeek = oeeDataFiltered.filter(it => it.weekNumber === week.weekNumber);
            const { hours, percentage } = aggregateAvailability(oeeDataWeek);
            aggregatedPerWeek.push({
                ...week,
                hoursLabel: hours.toFixed(0) + 'h',
                scoreLabel: percentage.toFixed(2),
                mainValue: percentage,
                barColor: getGraphColorSimple(true, percentage),
            });
        }
        return aggregatedPerWeek;
    }


    return (
        <div style={{ ...style, height: '100%' }}
            ref={graphContainerRef}
        />
    )
}


function aggregateAvailability(
    oeeData: OeeRow[]
): {
    percentage: number;
    hours: number;
} {
    const totalProductionTime = oeeData.reduce((acc, it) => acc + it.productionTime, 0);
    const totalStopTime = oeeData.reduce((acc, it) => acc + it.stopTime, 0);

    const percentage = (totalProductionTime + totalStopTime === 0)
        ? 0
        : totalProductionTime / (totalProductionTime + totalStopTime);
    const hours = totalProductionTime / 3600_000;

    return {
        percentage,
        hours
    };
}
