import { NUM_SPC_CHECKS, SpcChecksArray, spcCheckPosition } from '../../../shared/services/spc/spcCheckPosition';
import { FixedLengthArray } from '../../../shared/types/fixedLengthArray';
import { Position } from '../../../shared/types/protocol';
import { ProtocolFinal, RecordDraft, RecordWithProtocol } from '../../types/sharedTypeImpl';


export interface DataPoint {
    value: number | null,
    valueAsString: string | null,
    partCount: string,
    measurementStart: Date,
    isInRange: boolean,
    shouldDraw: boolean,
    shouldHighlight: boolean,
    checkResults: SpcChecksArray,
}

type DataPointInput = Omit<DataPoint, 'shouldHighlight' | 'checkResults'>;

const SPC_CHECKS_TO_HIGHLIGHT: FixedLengthArray<boolean, typeof NUM_SPC_CHECKS> = [
    true, true, true, true, true, true, true, false
]


export default function transformDataPoints(
    protocol: ProtocolFinal,
    prevRecords: RecordWithProtocol[],
    currentRecord: RecordDraft | null,
    selectedPositionIdx: number,
    enabledSpcChecks?: boolean[],
    maxDataPoints?: number | null,
): DataPoint[] {
    const position = protocol.positions[selectedPositionIdx];

    const inputData = [...getPrevPoints(prevRecords, position)];
    const currentPoint = currentRecord != null ? getCurrentPoint(currentRecord, selectedPositionIdx) : null;
    if (currentPoint != null) inputData.push(currentPoint);

    setIsInRange(inputData, maxDataPoints);
    const checkLastValue = currentPoint != null && currentPoint.shouldDraw;
    const outputData = setSpcFlags(inputData, protocol, selectedPositionIdx, checkLastValue, enabledSpcChecks);
    return outputData;
}

function getPrevPoints(prevRecords: RecordWithProtocol[], position: Position): DataPointInput[] {
    const dataPoints: DataPointInput[] = [];
    for (const record of prevRecords) {
        if (record.isDraft) continue;

        const positionIndex = record.protocol.positions.findIndex(it => it.positionNumber === position.positionNumber);
        if (positionIndex === -1) continue;
        if (record.protocol.positions[positionIndex].type === 'visualInspection') continue;
        if (record.positionValues[positionIndex]?.value == null) continue;
        if (record.protocol.positions[positionIndex].nominal != position.nominal) continue;

        const valueAsString = record.positionValues[positionIndex].value as string;
        const valueAsNumber = +valueAsString;
        dataPoints.push({
            value: valueAsNumber,
            valueAsString,
            partCount: record.partCount as string,
            measurementStart: record.measurementStart!,
            isInRange: true,
            shouldDraw: true,
        });
    }
    return dataPoints;
}

function getCurrentPoint(currentRecord: RecordDraft, selectedPositionIdx: number): DataPointInput {
    const valueAsString = currentRecord.positionValues[selectedPositionIdx].value as string;
    const valueAsNumber = valueAsString?.length > 0 ? Number.parseFloat(valueAsString) : null;
    const shouldDraw = valueAsNumber != null && !isNaN(valueAsNumber);
    return {
        value: valueAsNumber,
        valueAsString,
        partCount: currentRecord.partCount,
        measurementStart: currentRecord.measurementStart!,
        isInRange: true,
        shouldDraw,
    }
}

function setIsInRange(dataEntries: DataPointInput[], maxDataPoints: number | null | undefined) {
    if (maxDataPoints == null) return;
    const minIndexInRange = dataEntries.length - maxDataPoints;
    for (let i = 0; i < minIndexInRange; i++) {
        dataEntries[i].isInRange = false;
    }
}

function setSpcFlags(
    dataEntries: DataPointInput[],
    protocol: ProtocolFinal,
    selectedPositionIdx: number,
    includeLastValue: boolean,
    enabledSpcChecks?: boolean[],
): DataPoint[] {
    const realValues = dataEntries
        .slice(0, includeLastValue ? undefined : -1)
        .filter(it => it.valueAsString != null)
        .map(it => it.valueAsString as string);
    const position = protocol.positions[selectedPositionIdx];
    const finalChecks = enabledSpcChecks ?? [true, false, false, false, false, false, false, false];
    const positionResults = spcCheckPosition(realValues, position, finalChecks);

    const dataPoints: DataPoint[] = [];
    for (let i = 0; i < positionResults.length; i++) {
        dataPoints.push({
            ...dataEntries[i],
            shouldHighlight: positionResults[i].hasIssues && shouldhighlight(positionResults[i].checkResults),
            checkResults: positionResults[i].checkResults,
        });
    }
    if (!includeLastValue) {
        dataPoints.push({
            ...dataEntries[dataEntries.length - 1],
            shouldHighlight: false,
            checkResults: [null, null, null, null, null, null, null, null],
        });
    }
    return dataPoints;
}

function shouldhighlight(checkResults: SpcChecksArray): boolean {
    if (checkResults.length !== NUM_SPC_CHECKS) throw new Error('Invalid check results array length');
    return checkResults.some((it, idx) => it && SPC_CHECKS_TO_HIGHLIGHT[idx]);
}
