import { forwardRef, useImperativeHandle } from 'react';
import { Operation } from '../../types/sharedTypeImpl';
import { getToolsWithActiveAlarms } from '../../utils/toolChangeAlarms';
import { Tool } from './operationDraft';
import { OpSubFormProps, OpSubFormRef } from './operationSubForm';


interface FixedToolsProps extends OpSubFormProps {
    operationUnderEdit?: Operation | null;
    otherOperations?: Operation[];
}

export const FixedTools = forwardRef<OpSubFormRef, FixedToolsProps>(({
    operation,
    onOperationChange,
    operationUnderEdit,
    otherOperations,
}, ref) => {

    const includedTools = operation.fixedTools?.map(it => getKey(it)) ?? [];
    const toolOptions = getOptions(operationUnderEdit, otherOperations);

    useImperativeHandle(ref, () => ({
        validateWarnAndGet: (result) => {
            if (operation.fixedTools == null || operation.fixedTools.length == 0) {
                alert('Please select at least one tool to fix');
                return false;
            }

            result.fixedTools = operation.fixedTools;

            const fixedToolsString = operation.fixedTools.map(it => `${it.toolVariant} ${it.toolNumber}`).join('; ');
            result.details = [...(result.details ?? []), fixedToolsString];

            return true;
        }
    }));


    const handleChange = (tool: Tool, event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        const isToolIncluded = includedTools.includes(getKey(tool));

        if (isChecked && !isToolIncluded) {
            onOperationChange({
                ...operation,
                fixedTools: [...(operation.fixedTools ?? []), tool]
            });
        } else if (!isChecked && isToolIncluded) {
            onOperationChange({
                ...operation,
                fixedTools: operation.fixedTools?.filter(
                    it => it.toolNumber !== tool.toolNumber || it.toolVariant !== tool.toolVariant
                )
            });
        }
    }


    return (
        <fieldset className='dialog-form__fieldset'>
            {toolOptions.map(tool => (
                <label key={getKey(tool)}>
                    <input
                        type='checkbox'
                        name={'fixedTools'}
                        value={getKey(tool)}
                        checked={includedTools.includes(getKey(tool))}
                        onChange={(e => handleChange(tool, e))}
                    />
                    {`${tool.toolVariant} ${tool.toolNumber}`}
                </label>
            ))}
        </fieldset>
    );
});


function getOptions(editOperation: Operation | undefined | null, otherOperations?: Operation[]): Tool[] {
    if (otherOperations == null || otherOperations.length == 0) return [];

    const toolsNeedingFix = findToolsNeedingFix(otherOperations, editOperation?.time ?? null);
    const toolOptions = addExistingFixedTools(editOperation, toolsNeedingFix);
    return toolOptions;
}

function findToolsNeedingFix(operations: Operation[], endDate: Date | null): Tool[] {
    const operationsBeforeEnd = endDate != null ? operations.filter(it => it.time < endDate) : operations;
    const tools = getToolsWithActiveAlarms(operationsBeforeEnd);
    return tools;
}

function addExistingFixedTools(editOperation: Operation | undefined | null, toolsNeedingFix: Tool[]): Tool[] {
    const isEditOperationFix = editOperation?.operationType === 'Fix unscheduled tool change';
    if (!isEditOperationFix) return toolsNeedingFix;

    const tools = [...toolsNeedingFix];
    for (const fixedTool of editOperation?.fixedTools ?? []) {
        const isToolIncluded = tools.some(
            it => it.toolVariant === fixedTool.toolVariant && it.toolNumber === fixedTool.toolNumber
        );
        if (!isToolIncluded) {
            tools.push({
                toolVariant: fixedTool.toolVariant,
                toolNumber: fixedTool.toolNumber,
            });
        }
    }
    return tools;
}

function getKey(tool: Tool): string {
    return `${tool.toolVariant}-${tool.toolNumber}`;
}
