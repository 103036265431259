import { BatchDetails } from '../../shared/types/batchDetails';
import { CalibratedTool } from '../../shared/types/calibratedTool';
import { CmmReport, CmmReportHeader } from '../../shared/types/cmmReport';
import { DiagnosticsData } from '../../shared/types/diagnosticsData';
import { EnabledSpcChecks } from '../../shared/types/enabledSpcChecks';
import { Equipment } from '../../shared/types/equipment';
import { MachineLogKpi } from '../../shared/types/machineLogKpi';
import { ManagementViewKpis } from '../../shared/types/managementViewKpi';
import { MeasurementDelay } from '../../shared/types/measurementDelay';
import { CncEquipment } from '../../shared/types/mtlinkiTypes';
import { OperationRequestOptions } from '../../shared/types/operation';
import { OverlookEntry } from '../../shared/types/overlookEntry';
import { PageIssues } from '../../shared/types/pageIssues';
import { RecordDraftFront } from '../../shared/types/record';
import { SpcState } from '../../shared/types/spcState';
import { dateWithoutTime } from '../../shared/utils/dateUtils';
import { ProtocolImageEntry } from '../types/protocolImageEntry';
import { MaintenanceOperation, Measurement, MeasurementIntervalState, MeasurementUnsaved, NewOperation, OeeRow, Operation, Operator, ProgramNameMapping, Protocol, ProtocolWithRecord, Record, RecordDraft, RecordWithProtocol, Session } from '../types/sharedTypeImpl';
import { EntityTypes, convertFieldsToDateTyped, deleteJson, fetchWith, getJson, patchJson, postJson, putJson, sendFileAndData } from '../utils/fetchRequests';


export async function fetchIsLoggedIn(): Promise<boolean> {
    const isAdmin = await getJson(`/api/v1/users/isLoggedIn`);
    return isAdmin;
}

export async function logout(): Promise<string> {
    const response = await postJson(`/api/v1/logout`);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const responseBody = await response.json();
    return responseBody.redirectUrl;
}

export async function fetchIsAdmin(): Promise<boolean> {
    const isAdmin = await getJson(`/api/v1/users/isAdmin`);
    return isAdmin;
}

export async function fetchAppVersion(): Promise<string> {
    const version = await getJson(`/api/v1/app-version`);
    return version;
}

export async function fetchCncEquipments(): Promise<CncEquipment[]> {
    const equipments = await getJson(`/api/v1/equipments/cnc`);
    return equipments;
}

export async function fetchCustomEquipments(): Promise<Equipment[]> {
    const equipments = await getJson(`/api/v1/equipments/custom`);
    return equipments;
}

export async function postCustomEquipment(equipment: Equipment): Promise<boolean> {
    const response = await postJson(`/api/v1/equipments/custom`, equipment);
    return response.ok && response.status === 200;
}

export async function fetchProducts(): Promise<string[]> {
    const products = await getJson(`/api/v1/products`);
    return products;
}

export async function fetchPartNames(): Promise<string[]> {
    const partNames = await getJson(`/api/v1/products/part-names`);
    return partNames;
}


export async function fetchProductComment(partName: string): Promise<string> {
    const comment = await getJson(`/api/v1/products/comment/`, { partName });
    return comment;
}

export async function setProductComment(partName: string, product: string | null, productComment: string): Promise<void> {
    const response = await putJson(`/api/v1/products/comment`, {
        partName,
        product,
        productComment,
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}


export async function fetchSessions(
    partName: string | null | undefined,
    product: string | null | undefined,
): Promise<Session[]> {
    const query = {
        ...(partName && { partName }),
        ...(product && { product }),
    };
    const sessions = await getJson(`/api/v1/sessions`, query, EntityTypes.SESSION);
    return sessions;
}

export async function fetchCurrentSession(equipment: string): Promise<Session> {
    const session = await getJson(`/api/v1/equipments/${equipment}/current-session`, null, EntityTypes.SESSION);
    return session;
}

export async function fetchCurrentSessions(): Promise<Session[]> {
    const session = await getJson(`/api/v1/equipments/current-sessions`, null, EntityTypes.SESSION);
    return session;
}

export async function fetchBatchDetails(
    equipment: string,
    product: string | null,
    sessionStart: Date | null,
): Promise<BatchDetails | null> {
    try {
        const batchDetails = await getJson(`/api/v1/equipments/${equipment}/current-batch-details`, { product, sessionStart });
        return batchDetails;
    } catch (error) {
        return null;
    }
}

export async function postBatchDetails(
    equipment: string,
    product: string,
    sessionStart: Date,
    orderNumber: string,
    targetQuantity: number | null,
): Promise<void> {
    const data = { product, sessionStart, orderNumber, targetQuantity };
    const response = await postJson(`/api/v1/equipments/${equipment}/current-batch-details`, data);
    if (!response.ok) {
        const text = await response.text();
        throw new Error(text ?? '');
    }
}

export async function postMeasurement(
    measurement: MeasurementUnsaved
): Promise<{ measurement: Measurement, record: RecordDraftFront | null }> {
    const response = await postJson('/api/v1/measurement-intervals/measurements', measurement);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    const resultMeasurement = convertFieldsToDateTyped<Measurement>(result.measurement, ['sessionStart', 'time']);
    const resultRecord = result.record
        ? convertFieldsToDateTyped<RecordDraftFront>(result.record, ['sessionStart', 'measurementStart', 'measurementEnd'])
        : null;
    return { measurement: resultMeasurement, record: resultRecord };
}

export async function fetchProtocols(): Promise<Protocol[]> {
    const protocols = await getJson(`/api/v1/protocols/`, null, EntityTypes.PROTOCOL);
    return protocols;
}

export async function fetchProtocol(id: string): Promise<Protocol> {
    const protocol = await getJson(`/api/v1/protocols/${id}`, null, EntityTypes.PROTOCOL);
    return protocol;
}

export async function upsertProtocol(
    protocol: Protocol,
    pdf: File | Uint8Array | null,
    imageSet: ProtocolImageEntry[],
): Promise<Protocol> {
    const images: Blob[] = [];
    const imageIndices: { fileIndex: number, positionIndex: number }[] = [];
    for (const [index, imageEntry] of imageSet.entries()) {
        images.push(imageEntry.image);
        imageIndices.push({ fileIndex: index, positionIndex: imageEntry.positionIdx })
    }

    const pdfData = pdf ? { label: 'pdf', blob: new Blob([pdf], { type: 'application/pdf' }), } : null;
    const imagesData = { label: 'images', blob: images, };
    const protocolData = { label: 'protocol', obj: protocol, }
    const imageIndexData = { label: 'imageIndices', obj: imageIndices };

    const path = protocol._id ? `api/v1/protocols/${protocol._id}` : 'api/v1/protocols';
    const method = protocol._id ? 'PUT' : 'POST';
    const files = [pdfData, imagesData].filter(it => it != null) as { label: string, blob: Blob | Blob[] }[];
    const response = await sendFileAndData(path, method, files, [protocolData, imageIndexData]);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const rawObject = await response.json();
    return convertFieldsToDateTyped<Protocol>(
        rawObject,
        ['createTime']
    );
}

export async function patchProtocol(id: string, protocol: Partial<Protocol>): Promise<boolean> {
    const response = await patchJson(`/api/v1/protocols/${id}`, protocol);
    return response.ok && response.status === 200;
}

export async function fetchProtocolPdf(id: string): Promise<Uint8Array> {
    const response = await fetch(`/api/v1/protocols/${id}/pdf`);
    if (response.ok) {
        const buffer = await response.arrayBuffer();
        return new Uint8Array(buffer);
    } else {
        throw new Error('Request failed.');
    }
}

export async function fetchVisualInspectionImage(imageFileName: string): Promise<Uint8Array> {
    const response = await fetch(`/api/v1/protocols/positions/images/${imageFileName}`);
    const buffer = await response.arrayBuffer();
    return new Uint8Array(buffer);
}


export async function fetchRecords(filters?: {
    protocolId?: string,
    equipment?: string,
    sessionStart?: Date,
    search?: string,
    isDraft?: boolean,
    orderNumber?: string,
    onlyWithIssues?: boolean,
    limit?: number
}): Promise<Record[]> {
    const records = await getJson(
        `/api/v1/records`,
        { ...filters },
        EntityTypes.RECORD);
    return records;
}

export async function fetchRecordsWithProtocol(filters?: {
    protocolId?: string,
    equipment?: string,
    sessionStart?: Date,
    search?: string,
    isDraft?: boolean,
    orderNumber?: string,
    limit?: number
}): Promise<RecordWithProtocol[]> {
    const records = await getJson(
        `/api/v1/records`,
        { ...filters, include: 'protocol' },
        EntityTypes.RECORD_WITH_PROTOCOL);
    return records;
}

/* Start and end refer to the measurement start time. Start is inclusive and end is exclusive.
Results are returned ordered according to measurement start time in descending order. */
export async function fetchRecordsByProtocol(
    protocolId: string,
    equipment: string,
    start: Date | null | undefined,
    end: Date | null | undefined,
    limit: number
): Promise<RecordWithProtocol[]> {
    const records = await getJson(
        `/api/v1/records/by-protocol/${protocolId}`,
        {
            equipment,
            ...(start && { start }),
            ...(end && { end }),
            ...(limit && { limit }),
        },
        EntityTypes.RECORD_WITH_PROTOCOL);
    return records;
}

export async function fetchMeasurementProtocols(
    product: string | undefined,
    equipment: string | undefined,
): Promise<ProtocolWithRecord[]> {
    const protocols = await getJson(
        `/api/v1/protocols`,
        {
            equipment,
            product,
            status: 'approved',
            isNotDisabled: true,
            include: 'latestRecord',
        },
        EntityTypes.PROTOCOL_WITH_RECORD);
    return protocols;
}

export async function fetchRecord(id: string): Promise<Record> {
    const record = await getJson(`/api/v1/records/${id}`, null, EntityTypes.RECORD);
    return record;
}

export async function fetchLatestRecord(filters: {
    equipment?: string,
    orderNumber?: string,
    sessionStart?: Date,
    isDraft?: boolean,
}): Promise<Record> {
    const record = await getJson(`/api/v1/records/latest`, filters, EntityTypes.RECORD);
    return record;
}


export async function upsertRecord(record: Record | RecordDraft): Promise<Response> {
    const response = record._id
        ? await putJson(`api/v1/records/${record._id}`, record)
        : await postJson('api/v1/records', record);

    if (!response.ok) {
        const text = await response.text();
        throw new Error(text ?? '');
    }
    return response;
}

export async function deleteRecordWithRelated(record: Record | RecordDraft): Promise<void> {
    const response = await deleteJson(`api/v1/records/${record._id}/with-related`);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function fetchProgramNameMappings(filters?: { isHidden?: boolean }): Promise<ProgramNameMapping[]> {
    const mappings = await getJson(`/api/v1/program-name-mappings`, filters);
    return mappings;
}

export async function fetchCalibratedTools(): Promise<CalibratedTool[]> {
    const protocols = await getJson(`/api/v1/calibratedTools/`, null, EntityTypes.PROTOCOL);
    return protocols;
}

export async function fetchOverlookTable(): Promise<OverlookEntry[] | null> {
    const version = await getJson(`/api/v1/overlook`, null, EntityTypes.OVERLOOK);
    return version;
}


export async function fetchOperations(filters: {
    equipment?: string,
    product?: string,
    start?: Date,
    end?: Date | null,
    hasPendingPotentialScrap?: boolean,
    hasScrapCloseForm?: boolean,
    minScrapCloseTime?: Date,
    hasScrapLabel?: boolean,
}): Promise<Operation[]> {
    const operations = await getJson(`/api/v1/operations`, filters, EntityTypes.OPERATION) as Operation[];
    return operations;
}

export async function postOperation(
    operation: NewOperation,
    options?: OperationRequestOptions,
): Promise<Operation> {
    const data = {
        operation,
        options,
    }
    const response = await postJson('/api/v1/operations', data);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    const returnOperation = convertFieldsToDateTyped<Operation>(result, ['time']);
    return returnOperation;
}

export async function updateOperation(
    operation: Operation,
    options?: OperationRequestOptions,
): Promise<Operation> {
    const data = {
        operation,
        options,
    }
    const response = await patchJson(`/api/v1/operations/${operation._id}`, data);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    const returnOperation = convertFieldsToDateTyped<Operation>(result, ['time']);
    return returnOperation;
}

export async function releaseScrapLabel(operationId: string) {
    const response = await postJson(`/api/v1/operations/${operationId}/release-label`);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}


export async function fetchMaintenceOperations(equipment: string): Promise<MaintenanceOperation[]> {
    const operations = await getJson('/api/v1/operations/maintenance', { equipment }, EntityTypes.MAINTENANCE_OPERATION);
    return operations;
}

export async function postMaintenanceOperation(
    operation: Required<Pick<MaintenanceOperation, 'equipment' | 'operationType' | 'comment' | 'operator'>>
): Promise<void> {
    const response = await postJson('/api/v1/operations/maintenance', operation);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}


export async function fetcMeasurementIntervalTable(): Promise<MeasurementIntervalState[]> {
    const table = await getJson(`/api/v1/measurement-intervals`, null, EntityTypes.MEASUREMENT_INTERVAL_ROW);
    return table;
}

export async function fetchRecordsReportCsv(
    orderNumber: string,
): Promise<Blob | null> {
    const response = await fetchWith(`/api/v1/records/report`, { orderNumber });
    if (response.ok) {
        return await response.blob();
    } else {
        return null;
    }
}

export async function fetchSessionKpis(
    session: Session,
    abortController: AbortController
): Promise<MachineLogKpi> {
    const kpi = await getJson(`/api/v1/machine-log-kpis`, {
        equipment: session.equipment,
        product: session.product,
        start: session.start,
        ...(session.end && { end: session.end }),
    }, EntityTypes.MACHINE_LOG_KPI, abortController);
    return kpi;
}

export async function fetchManagementViewKpis(equipment: string): Promise<ManagementViewKpis> {
    const kpi = await getJson(`/api/v1/management-view-kpis`, { equipment }, EntityTypes.MANAGEMENT_VIEW_KPI);
    return kpi;
}

export async function fetchSpcStates(): Promise<SpcState[]> {
    const spcStates = await getJson(`/api/v1/spc/current`, null, EntityTypes.SPC_STATE);
    return spcStates;
}


export async function fetchEnabledSpcChecks(product: string): Promise<EnabledSpcChecks[]> {
    const enabledChecks = await getJson(`/api/v1/spc/enabled-checks/${product}`);
    return enabledChecks;
}

export async function putEnabledSpcChecks(
    product: string,
    positionNumber: string,
    enabledChecks: boolean[]
) {
    const response = await putJson(`/api/v1/spc/enabled-checks/${product}/${positionNumber}`, enabledChecks);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}


export async function fetchOee({
    windowType,
    equipment,
    start,
    end,
}: {
    windowType: 'day' | 'shift' | 'hour',
    equipment?: string,
    start: Date,
    end?: Date,
}): Promise<OeeRow[]> {
    const oee = await getJson(`/api/v1/graphs-data/oee`, { windowType, equipment, start, end }, EntityTypes.OEE);
    return oee;
}

export async function fetchLiveGraphsData(): Promise<LiveGraphsResult> {
    const start = dateWithoutTime(new Date());
    start.setDate(start.getDate() - 7);
    const shiftTableRange = { start, end: new Date() };

    const result = await Promise.all([
        getJson(`/api/v1/equipments/cnc`),
        getJson(`/api/v1/graphs-data/oee`, { windowType: 'shift', ...shiftTableRange }, EntityTypes.OEE),
        getJson(`/api/v1/graphs-data/oee`, { windowType: 'hour', ...shiftTableRange }, EntityTypes.OEE),
        getJson(`/api/v1/graphs-data/oee`, { windowType: 'live' }, EntityTypes.OEE),
    ]);
    return {
        equipments: result[0],
        shiftTable: result[1],
        hourTable: result[2],
        liveTable: result[3],
    };
}

interface LiveGraphsResult {
    equipments: CncEquipment[];
    shiftTable: OeeRow[];
    hourTable: OeeRow[];
    liveTable: OeeRow[];
}


export async function fetchMeasurementDelays(start: Date, end: Date): Promise<MeasurementDelay[]> {
    const delays = await getJson(`/api/v1/graphs-data/measurement-delays`, { start, end }, EntityTypes.MEASUREMENT_DELAY);
    return delays;
}


export async function fetchOperators(): Promise<Operator[]> {
    const users = await getJson(`/api/v1/operators`);
    return users;
}

export async function putOperator(operator: Operator): Promise<void> {
    const response = await putJson(`/api/v1/operators/${operator._id}`, operator);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}


export async function fetchPartCountsAt(session: Session, times: Date[]): Promise<number[]> {
    const response = await postJson(
        '/api/v1/sessions/get-part-counts-at',
        { session, times, }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const partCounts = await response.json() as number[];
    return partCounts;
}


export async function putPrice(partName: string, product: string, productPrice: number): Promise<boolean> {
    const response = await putJson(`/api/v1/products/price`, { partName, product, productPrice });
    return response.ok && response.status === 200;
}

export async function fetchCmmReportHeaders(startsWith?: string): Promise<CmmReportHeader[]> {
    const cmmReports = await getJson('/api/v1/cmm-reports/headers', { startsWith }, EntityTypes.CMM_REPORT_HEADER) as CmmReportHeader[];
    return cmmReports;
}

export async function fetchCmmReportsWithFiles(startsWith?: string): Promise<CmmReport[]> {
    const cmmReports = await fetchCmmReportHeaders(startsWith);
    const files = await Promise.all(cmmReports.map(it => fetchCmmReportFile(it.name)));

    const reportsWithFiles = cmmReports.map((it, index) => ({
        ...it,
        file: files[index],
    }));
    return reportsWithFiles;
}

export async function fetchCmmReportFile(name: string): Promise<File> {
    const response = await fetchWith(`/api/v1/cmm-reports/file`, { name });
    if (!response.ok) {
        throw new Error('Failed to fetch file');
    }

    const blob = await response.blob();
    const file = new File([blob], name);
    return file;
}

export async function archiveCmmReports(names: string[]): Promise<void> {
    const response = await postJson('/api/v1/cmm-reports/archive', { names });
    if (!response.ok) {
        throw new Error('Failed to archive reports');
    }
}

export async function fetchPageIssues(): Promise<PageIssues> {
    const issues = await getJson('/api/v1/page-issues', null);
    return issues;
}

export async function fetchDiagnostics(): Promise<DiagnosticsData> {
    const diagnostics = await getJson(`/api/v1/diagnostics`, null, EntityTypes.DIAGNOSTICS);
    return diagnostics;
}

export async function postServerRestart(userMessage: string, diagnostics?: DiagnosticsData) {
    postJson(`/api/v1/diagnostics/restart`, {
        userMessage,
        diagnostics,
    });
}
