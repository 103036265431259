import { useEffect, useState } from 'react';
import { fetchRecordsWithProtocol } from '../../api/requests';
import useFetchOnce from '../../hooks/useFetchOnce';
import { saveCsvWithPicker } from '../../utils/saveFile';
import ModalDialog from '../modalDialog';
import createPreviewTable from './createPreviewTable';
import exportPreviewToCsv from './exportPreviewToCsv';
import { RecordReportTable } from './recordReportTable';
import { PreviewTable } from './types';


interface RecordsReportDialogProps {
    orderNumber: string,
    onClose: () => void,
}

export default function RecordsReportDialog({
    orderNumber,
    onClose,
}: RecordsReportDialogProps) {

    const [previewTable, setPreviewTable] = useState<PreviewTable | undefined>(undefined);


    const { data: records, isLoading: isRecordsLoading } = useFetchOnce({
        queryKey: ['fetchRecordsWithProtocol', orderNumber],
        queryFn: async () => await fetchRecordsWithProtocol({ orderNumber, isDraft: false }),
    });

    useEffect(() => {
        if ( records) {
            setPreviewTable(createPreviewTable(records));
        } else {
            setPreviewTable(undefined);
        }
    }, [records]);


    const handleIsIncludedChange = (recordIdx: number, isSelected: boolean) => {
        if (!previewTable) return;
        const table = structuredClone(previewTable);
        table.recordRows[recordIdx].includeInReport = isSelected;
        setPreviewTable(table);
    }

    const handleExport = () => {
        if (!previewTable) return;
        const csv = exportPreviewToCsv(previewTable);
        saveCsvWithPicker(`Report ${orderNumber}.csv`, csv);
        onClose();
    }


    return (
        <ModalDialog
            isOpen={true}
            onCancel={onClose}
        >
            <p>Order number: {orderNumber}</p>

            {isRecordsLoading || !previewTable
                ? <p>Loading...</p>
                : <>
                    <RecordReportTable
                        previewTable={previewTable}
                        onIsIncludedChange={handleIsIncludedChange}
                    />
                    <div className='dialog--button-bar'>
                        <button onClick={onClose}>Cancel</button>
                        <button onClick={handleExport}>Export</button>
                    </div>
                </>
            }
        </ModalDialog>
    );
}
