import { useQuery } from '@tanstack/react-query';
import { sortBy } from '../../../shared/utils/arrayUtils';
import { fetchProtocol, fetchRecordsWithProtocol, fetchSessions } from '../../api/requests';


interface UseLoadRecordsProps {
    equipment: string;
    product: string;
}

export function useLoadRecords({
    equipment,
    product,
}: UseLoadRecordsProps) {

    const { data: sessions } = useQuery({
        queryKey: ['sessions', product],
        queryFn: async () => await fetchSessions(undefined, product),
        enabled: product != undefined,
    });
    const latestSession = sessions?.at(-1);

    const { data: records } = useQuery({
        queryKey: ['fetchRecordsWithProtocol', equipment, latestSession?.start, false],
        queryFn: async () => await fetchRecordsWithProtocol({
            equipment, sessionStart: latestSession!.start, isDraft: false
        }),
        select: it => sortBy([...it], it => it.measurementStart),
        enabled: !!latestSession,
    });
    const latestRecord = records?.at(-1);
    const orderNumber = latestRecord?.orderNumber;

    const { data: protocol } = useQuery({
        queryKey: ['protocols', latestRecord?.protocolId],
        queryFn: async () => await fetchProtocol(latestRecord!.protocolId),
        enabled: latestRecord?.protocolId != undefined,
    });


    return {
        records,
        protocol,
        orderNumber,
    };
}
