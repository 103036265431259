import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Point } from '../../../shared/types/mathTypes';
import { Marker, Position } from '../../../shared/types/protocol';
import PositionSelection from '../../types/positionSelection';
import ArrowMarker, { ArrowMarkerTrigger } from './arrowMarker';
import CircleMarker, { CircleMarkerTriggers } from './circleMarker';


export interface PositionMarkersRef {
    updatePositions: () => void;
}

interface PositionMarkerProps {
    positions?: Position[];
    positionSelection: PositionSelection | null;
    showAllPositions?: boolean;
    page: number;
    pdfToScreenCoords: (pdfCoords: Point) => Point;
}


const PositionMarkers = forwardRef<PositionMarkersRef, PositionMarkerProps>(({
    positions,
    positionSelection,
    showAllPositions,
    page,
    pdfToScreenCoords,
}, ref) => {
    const markerRefs = useRef(new Map<string, CircleMarkerTriggers | ArrowMarkerTrigger | null>(null));


    useImperativeHandle(ref, () => ({
        updatePositions,
    }));

    function updatePositions() {
        markerRefs.current.forEach((ref) => {
            ref?.updatePosition();
        });
    }


    return (
        <>
            {positions?.map((position, positionIdx) =>
                position.markers.map((marker, markerIdx) =>
                    <PositionMarker
                        key={positionIdx + '-' + markerIdx}
                        markerIdx={markerIdx}
                        positionIdx={positionIdx}
                        position={position}
                        marker={marker}
                        page={page}
                        positionSelection={positionSelection}
                        showAllPositions={showAllPositions}
                        pdfToScreenCoords={(pdfCoords) => pdfToScreenCoords(pdfCoords)}
                        setRef={(ref) => markerRefs.current.set(positionIdx + '-' + markerIdx, ref)}
                    />
                )
            )}
        </>
    );
});

export default PositionMarkers;


function PositionMarker({
    markerIdx,
    positionIdx,
    position,
    marker,
    page,
    positionSelection,
    showAllPositions,
    pdfToScreenCoords,
    setRef,
}: {
    markerIdx: number,
    positionIdx: number,
    position: Position,
    marker: Marker,
    page: number,
    positionSelection: PositionSelection | null,
    showAllPositions?: boolean,
    pdfToScreenCoords: (pdfCoords: Point) => Point,
    setRef: (ref: CircleMarkerTriggers | ArrowMarkerTrigger | null) => void,
}) {

    const isSelectedOptimistic = positionIdx === positionSelection?.positionIdx && (
        markerIdx === positionSelection?.markerIdx || positionSelection?.markerIdx == null
    );
    const isVisible = position.page === page && (showAllPositions || isSelectedOptimistic);
    const showCircle = isVisible && marker.x2 == null;
    const showArrow = isVisible && marker.x2 != null && marker.y2 != null;
    const showDescription = isVisible && isSelectedOptimistic;

    return (
        <>
            {showCircle &&
                <CircleMarker
                    ref={(ref) => setRef(ref)}
                    pdfPosition={{ x: marker.x, y: marker.y }}
                    pdfToScreenCoords={pdfToScreenCoords}
                    sizeFactor={marker.sizeFactor}
                    description={showDescription ? marker.text : undefined}
                />
            }
            {showArrow &&
                <ArrowMarker
                    ref={(ref) => setRef(ref)}
                    pdfStart={{ x: marker.x, y: marker.y }}
                    pdfEnd={{ x: marker.x2!, y: marker.y2! }}
                    pdfToScreenCoords={pdfToScreenCoords}
                    description={showDescription ? marker.text : undefined}
                />
            }
        </>
    );
}
