import { ControlValues, getControlValues } from '../../../shared/services/spc/getControlValues';
import { MIN_POINTS_FOR_SPC } from '../../../shared/services/spc/spcCheckPosition';
import { getToleranceRange } from '../../../shared/utils/getToleranceRange';
import { clamp } from '../../../shared/utils/minMaxUtils';
import { ProtocolFinal } from '../../types/sharedTypeImpl';
import MeasurementGraphSettings from './measurementGraphSettings';
import { DataPoint } from './transformDataPoints';


export interface MetaData {
    //from protocol
    nominal: number,
    tolerances: [number, number],

    //from max and min values
    graphLimits: [number, number],
    valueLimits?: [number, number],

    //from historical data
    mean?: number,
    sigma1?: [number, number],
    sigma2?: [number, number],
    sigma3?: [number, number],
    canDoSpc: boolean,
    isLastValueFlagged?: boolean,
}

export default function getMetaData(
    data: DataPoint[],
    protocol: ProtocolFinal,
    selectedPositionIdx: number,
    settings: MeasurementGraphSettings,
): MetaData {
    const position = protocol.positions[selectedPositionIdx];
    const { nominal } = position;

    const realValues = data
        .filter(it => it.shouldDraw && it.valueAsString != null)
        .map(it => it.valueAsString as string);
    const controlValues = getControlValues(realValues, position);
    const { min: toleranceMin, max: toleranceMax } = getToleranceRange(position);//Get value from position, not control values, in case data length is zero

    const visibleValues = data
        .filter(it => it.shouldDraw && it.isInRange && it.value != null)
        .map(it => it.value as number);
    const [graphMin, graphMax] = visibleRange(
        visibleValues, +nominal!, toleranceMin, toleranceMax, controlValues, settings);

    //Value limits for data points (helps avoid lines that look completely vertical)
    const valueMin = graphMin - (graphMax - graphMin) * 0.5;
    const valueMax = graphMax + (graphMax - graphMin) * 0.5;

    const canDoSpc = realValues.length >= MIN_POINTS_FOR_SPC;
    const isLastValueFlagged = data?.at(-1)?.shouldHighlight ?? false;

    return {
        nominal: +nominal!,
        tolerances: [toleranceMin, toleranceMax],

        graphLimits: [graphMin, graphMax],
        valueLimits: [valueMin, valueMax],

        mean: controlValues?.mean,
        sigma1: controlValues?.sigma1,
        sigma2: controlValues?.sigma2,
        sigma3: controlValues?.sigma3,
        canDoSpc,
        isLastValueFlagged,
    }
}

// Controls what data range will be visible on the graph
// Usually the limit is set by the control values, but if some points are outside the range,
// then the graph will be adjusted up to 30% beyond the control values. If there are no control values,
// then the limits are set to the min and max values of the data.
function visibleRange(
    values: number[],
    nominal: number,
    toleranceMin: number,
    toleranceMax: number,
    controlValues: ControlValues | null,
    settings: MeasurementGraphSettings,
): [number, number] {
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    const visibilities = settings.lineVisibilities;
    const areAllHidden = !(visibilities.tolerance || visibilities.sigma1 || visibilities.sigma2 || visibilities.sigma3);

    const minControlValue = Math.min(...[
        visibilities.nominal ? nominal : null,
        visibilities.tolerance ? toleranceMin : null,
        visibilities.sigma1 ? controlValues?.sigma1[0] : null,
        visibilities.sigma2 ? controlValues?.sigma2[0] : null,
        visibilities.sigma3 ? controlValues?.sigma3[0] : null,
        areAllHidden ? minValue : null,
    ].filter(it => it != null) as number[]);

    const maxControlValue = Math.max(...[
        visibilities.nominal ? nominal : null,
        visibilities.tolerance ? toleranceMax : null,
        visibilities.sigma1 ? controlValues?.sigma1[1] : null,
        visibilities.sigma2 ? controlValues?.sigma2[1] : null,
        visibilities.sigma3 ? controlValues?.sigma3[1] : null,
        areAllHidden ? maxValue : null,
    ].filter(it => it != null) as number[]);


    const maxValueOffset = (maxControlValue - minControlValue) * 0.3;// How much the graph may extend beyond the control values
    const margin = (maxControlValue - minControlValue) * 0.05;

    const minVisible = clamp(
        minValue,
        minControlValue - maxValueOffset,
        minControlValue,
    ) - margin;
    const maxVisible = clamp(
        maxValue,
        maxControlValue,
        maxControlValue + maxValueOffset,
    ) + margin;

    return [minVisible, maxVisible];
}
