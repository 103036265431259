import { nullish } from '../types/nullish.js';


export function minNotNull(a: number | nullish, b: Date): number;
export function minNotNull(a: number, b: number | nullish): number;
export function minNotNull<T extends number | nullish>(a: T, b: T): T;
export function minNotNull<T extends number | nullish>(a: T, b: T): T {
    if (a == null) {
        return b;
    }
    if (b == null) {
        return a;
    }
    return a < b ? a : b;
}

export function maxNotNull(a: number | nullish, b: number): number;
export function maxNotNull(a: number, b: number | nullish): number;
export function maxNotNull<T extends number | nullish>(a: T, b: T): T;
export function maxNotNull<T extends number | nullish>(a: T, b: T): T {
    if (a == null) {
        return b;
    }
    if (b == null) {
        return a;
    }
    return a > b ? a : b;
}


export function minDateNotNull(date1: Date | nullish, date2: Date): Date;
export function minDateNotNull(date1: Date, date2: Date | nullish): Date;
export function minDateNotNull<T extends Date | nullish>(date1: T, date2: T): T;
export function minDateNotNull<T extends Date | nullish>(date1: T, date2: T): T {
    if (date1 == null) {
        return date2;
    }
    if (date2 == null) {
        return date1;
    }
    return date1 < date2 ? date1 : date2;
}

export function maxDateNotNull(date1: Date | nullish, date2: Date): Date;
export function maxDateNotNull(date1: Date, date2: Date | nullish): Date;
export function maxDateNotNull<T extends Date | nullish>(date1: T, date2: T): T;
export function maxDateNotNull<T extends Date | nullish>(date1: T, date2: T): T {
    if (date1 == null) {
        return date2;
    }
    if (date2 == null) {
        return date1;
    }
    return date1 > date2 ? date1 : date2;
}

export function maxDateOrInfinity(date1: Date, date2: Date): Date;
export function maxDateOrInfinity(date1: Date | null, date2: Date): Date;
export function maxDateOrInfinity(date1: Date, date2: Date | null): Date;
export function maxDateOrInfinity(date1: Date | null, date2: Date | null): Date | null;
export function maxDateOrInfinity(date1: Date | null, date2: Date | null): Date | null {
    if (date1 === null || date2 === null) {
        return null;
    }
    return date1 > date2 ? date1 : date2;
}


export function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
}

/** If value is null, max date is returned. If both value and max are null, then min is returned (which
 * goes against the idea of null meaning infinity). */
export function clampDate(value: Date | null, min: Date, max: Date | null): Date {
    //Must compare against max value first, so that when value is null, then max is returned
    return maxDateNotNull(minDateNotNull(value, max), min);
}
