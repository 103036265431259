import NavBar from '../../components/navBar';
import { PageTitle } from '../../components/pageTitle';
import Diagnostics from './diagnostics';
import ReleaseNotes from './releaseNotes';


export default function Index() {

    // const { data: hello } = trpc.hello.useQuery('world');
    // console.log(hello);

    return (
        <>
            <PageTitle title='BG Web Apps' />
            <NavBar />
            <div className='content content--index'>
                <Diagnostics />
                <ReleaseNotes />
            </div>
        </>
    );
}
