import { Operator } from '../../types/sharedTypeImpl';


interface OperatorsTableProps {
    style: React.CSSProperties;
    operators: Operator[];
    onIsActiveChange: (operator: Operator, isActive: boolean) => void;
}

export default function OperatorsTable({
    style,
    operators,
    onIsActiveChange,
}: OperatorsTableProps) {
    return (
        <table style={style}>
            <thead>
                <tr>
                    <th>Is active</th>
                    <th>Full name</th>
                    <th>Initials</th>
                </tr>
            </thead>
            <tbody>
                {operators?.map(it =>
                    <tr key={it._id}>
                        <td>
                            <input
                                type='checkbox'
                                checked={it.isActive}
                                onChange={(e) => onIsActiveChange(it, e.target.checked)}
                            />
                        </td>
                        <td>{it.fullName}</td>
                        <td>{it.initials}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
