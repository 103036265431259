import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Equipment } from '../../../shared/types/equipment';
import { fetchCustomEquipments, fetchOperators, putOperator } from '../../api/requests';
import NavBar from '../../components/navBar';
import { PageTitle } from '../../components/pageTitle';
import { Operator } from '../../types/sharedTypeImpl';
import CustomEquipmentDialog from './customEquipmentDialog';
import CustomEquipmentsTable from './customEquipmentsTable';
import OperatorDialog from './operatorDialog';
import OperatorsTable from './operatorsTable';


export default function Resources() {

    const [isOperatorDialogOpen, setIsOperatorDialogOpen] = useState(false);
    const [isEquipmentDialogOpen, setIsEquipmentDialogOpen] = useState(false);


    const operatorsQuery = useQuery({
        queryKey: ['operators'],
        queryFn: fetchOperators,
        select: (operators) => [...operators].sort((a, b) => a.fullName.localeCompare(b.fullName)),
    });
    const operators = operatorsQuery.data;

    const equipmentsQuery = useQuery({
        queryKey: ['custom-equipments'],
        queryFn: fetchCustomEquipments,
        select: (equipments) => [...equipments].sort((a, b) => a.displayName.localeCompare(b.displayName)),
    });
    const equipments = equipmentsQuery.data;


    const handleAddOperatorClick = () => {
        setIsOperatorDialogOpen(true);
    }

    const handleIsActiveChange = async (operator: Operator, isActive: boolean) => {
        await putOperator({ ...operator, isActive });
        operatorsQuery.refetch();
    }

    const handleAddEquipmentClick = () => {
        setIsEquipmentDialogOpen(true);
    }


    return (
        <>
            <PageTitle title='Resources' />
            <NavBar />
            <div className='content' style={{ paddingBottom: '20px' }}>
                <div>
                    <h2>Operators</h2>
                    <button onClick={handleAddOperatorClick}>Add operator</button>
                    {operatorsQuery.isLoading && <div>Loading...</div>}
                    {operatorsQuery.error && <div>Error retrieving data</div>}
                    {operators && <OperatorsTable
                        style={{ width: '800px' }}
                        operators={operators}
                        onIsActiveChange={handleIsActiveChange}
                    />}
                </div>

                <div>
                    <h2 style={{ marginTop: '40px' }}>Custom equipments</h2>
                    <button onClick={handleAddEquipmentClick}>Add equipment</button>
                    {equipmentsQuery.isLoading && <div>Loading...</div>}
                    {equipmentsQuery.error && <div>Error retrieving data</div>}
                    {equipments && <CustomEquipmentsTable
                        style={{ width: '800px' }}
                        equipments={equipments}
                    />}
                </div>
            </div>

            {(isOperatorDialogOpen && operators) && <OperatorDialog
                operators={operators}
                onOperatorAdded={(operator: Operator) => operatorsQuery.refetch()}
                onClose={() => { setIsOperatorDialogOpen(false) }}
            />}
            {(isEquipmentDialogOpen && equipments) && <CustomEquipmentDialog
                equipments={equipments}
                onEquipmentAdded={(equipment: Equipment) => equipmentsQuery.refetch()}
                onClose={() => { setIsEquipmentDialogOpen(false) }}
            />}
        </>
    );
}
