import * as d3 from 'd3';
import { maxBy } from '../../../shared/utils/arrayUtils';
import { SigmaHistogramData } from './getHistogramData';


type SVGElement = d3.Selection<SVGSVGElement, unknown, null, undefined>;

type GraphDimens = {
    width: number,
    height: number,
    margins: { top: number, right: number, bottom: number, left: number },
}
type Graph = d3.Selection<SVGGElement, GraphDimens, null, undefined>;


export function drawSigmaHistogram(
    container: HTMLDivElement,
    histogramData: SigmaHistogramData | null,
) {
    const root = createRootElement(container);

    if (histogramData == null) {
        drawNoDataMessage(root);
        return;
    }

    const graph = createGraphElement(root);
    const axes = createAxes(root, graph, histogramData);
    drawData(graph, axes, histogramData);
}


function createRootElement(container: HTMLDivElement) {
    const rect = container.getBoundingClientRect();
    const containerWidth = rect.width;
    const containerHeight = rect.height;

    let root = d3.select(container).select('svg') as SVGElement;
    if (root.empty()) {
        root = d3.select(container)
            .append('svg')
            .attr('width', containerWidth)
            .attr('height', containerHeight)
            .attr('class', 'graph');
    } else {
        root.html('');
    }
    return root;
}

function drawNoDataMessage(root: SVGElement) {
    root.append('text')
        .attr('x', +root.attr('width') / 2)
        .attr('y', +root.attr('height') / 2)
        .attr('text-anchor', 'middle')
        .text('No data available');
}

function createGraphElement(root: SVGElement): Graph {
    const graphMargins = { top: 15, right: 20, bottom: 40, left: 50 };
    const graphWidth = +root.attr('width') - graphMargins.left - graphMargins.right;
    const graphHeight = +root.attr('height') - graphMargins.top - graphMargins.bottom;

    let graph = root.select('g') as Graph;
    if (graph.empty()) {
        graph = root.append('g').attr('transform', `translate(${graphMargins.left}, ${graphMargins.top})`) as Graph;
    }
    graph.datum({ width: graphWidth, height: graphHeight, margins: graphMargins });

    return graph;
}

function createAxes(
    root: SVGElement,
    graph: Graph,
    histogramData: SigmaHistogramData,
) {
    const textSize = 14;
    const { width, height, margins } = graph.datum();

    const [lowLabels, highLabels] = splitArray(histogramData.histogram.map((bucket) => bucket.label));
    const namedLabels = [...lowLabels, 'Mean', ...highLabels];
    const valueLabels = ['', ...histogramData.limitValues.map((value) => value.toFixed(3)), ''];
    const finalLabels = namedLabels.map((namedLabel, index) => ({ namedLabel, valueLabel: valueLabels[index] }));

    const x = d3
        .scalePoint() // Use scalePoint so that the scale labels are between the bars
        .range([0, width])
        .domain([...namedLabels.map((_, index) => '' + index)]);
    const xAxis = root.append('g')
        .attr('transform', `translate(${margins.left}, ${height + margins.top})`)
        .call(d3.axisBottom(x));

    // Replace default tick labels with custom ones
    xAxis.selectAll('.tick text').remove();
    const ticks = xAxis
        .selectAll('.custom-tick')
        .data(finalLabels)
        .enter()
        .append('g')
        .attr('class', 'custom-tick')
        .attr('class', '.tick text')
        .attr('transform', (d, index) => `translate(${(x('' + index) || 0) + x.bandwidth() / 2}, 0)`);
    ticks.append('text')
        .attr('y', 1.5 * textSize)
        .attr('text-anchor', 'middle')
        .attr('fill', 'currentColor')
        .text(d => d.namedLabel)
        .style('font-size', textSize + 'px');
    ticks.append('text')
        .attr('y', 2.8 * textSize)
        .attr('text-anchor', 'middle')
        .attr('fill', 'currentColor')
        .text(d => d.valueLabel)
        .style('font-size', textSize + 'px');

    const y = d3
        .scaleLinear()
        .range([height, 0])
        .domain([0, maxBy(histogramData.histogram, it => it.count)!.count || 1]);
    root.append('g')
        .attr('transform', `translate(${margins.left}, ${margins.top})`)
        .call(d3.axisLeft(y))
        .selectAll('text')
        .style('font-size', textSize + 'px');

    return { x, y };
}

function drawData(
    graph: Graph,
    { x, y }: ReturnType<typeof createAxes>,
    histogramData: SigmaHistogramData,
) {
    const barPadding = 1;
    const barColors = [
        '#36c22f',
        '#8ac22f',
        '#dac400',
        '#D64550',
    ];

    const { height: graphHeight } = graph.datum();
    const barColorsFinal = [...barColors.slice().reverse(), ...barColors];
    graph.selectAll('rect')
        .data(histogramData.histogram)
        .enter()
        .append('rect')
        .attr('x', (_, index) => x('' + index)! + barPadding + 0.5)
        .attr('y', (bucket) => y(bucket.count))
        .attr('width', x.step() - 2 * barPadding)
        .attr('height', (bucket) => graphHeight - y(bucket.count))
        .attr('fill', (_, index) => barColorsFinal[index]);
}


function splitArray<T>(arr: T[]): [T[], T[]] {
    const middleIndex = Math.ceil(arr.length / 2);
    const firstHalf = arr.slice(0, middleIndex);
    const secondHalf = arr.slice(middleIndex);

    return [firstHalf, secondHalf];
}
