import { useRef, useState } from 'react';
import { releaseScrapLabel } from '../../api/requests';
import NavBar, { NavBarRef } from '../../components/navBar';
import OperationDialog from '../../components/operation-dialog/operationDialog';
import OperationsTable from '../../components/operation-table/operationsTable';
import { PageTitle } from '../../components/pageTitle';
import ScrapCloseDialog from '../../components/scrapCloseDialog';
import { Operation } from '../../types/sharedTypeImpl';
import { useFetchOperations } from './useFetchOperations';


export default function PotentialScraps() {
    const [operationUnderEdit, setOperationUnderEdit] = useState<Operation | null>(null);
    const [operationUnderScrapClose, setOperationUnderScrapClose] = useState<Operation | null>(null);
    const navBarRef = useRef<NavBarRef>(null);

    const {
        pendingOperations,
        handledOperations,
        refetchHandledOperations,
        refetchAllOperations,
    } = useFetchOperations();


    const handleOperationEditClick = (operation: Operation) => {
        if (operation.scrapCloseForm) {
            setOperationUnderScrapClose(operation);
        } else {
            setOperationUnderEdit(operation);
        }
    }

    const handleScrapCloseClick = (operation: Operation) => {
        setOperationUnderScrapClose(operation);
    }

    const handleLabelReleaseClick = async (operation: Operation) => {
        const isConfirmed = confirm(`Release label ${operation.scrapLabel} of part ${operation.partDisplayName}?`);
        if (isConfirmed) {
            await releaseScrapLabel(operation._id!);
            refetchHandledOperations();
        }
    }

    const onOperationSaved = () => {
        refetchAllOperations();
        navBarRef.current?.refetchPageIssues();
    }


    return (
        <>
            <PageTitle title='Scraps' />
            <NavBar ref={navBarRef} />
            <div className='content'>
                {pendingOperations == null
                    ? 'Loading...'
                    : <>
                        <h2>Pending potential scraps</h2>
                        <OperationsTable
                            narrow={false}
                            operations={pendingOperations}
                            showSession={true}
                            operationTypeIndicators={false}
                            highlightPotentialDiscards={false}
                            showScrapLabels={true}
                            enableLabelRelease={false}
                            showOrderNumbers={true}
                            isEditableIfScrapClosed={true}
                            enableScrapClose={true}
                            showScrapCloseValues={false}
                            onEditClick={handleOperationEditClick}
                            onScrapCloseClick={handleScrapCloseClick}
                        />
                    </>
                }

                {handledOperations && <>
                    <h2 style={{ marginTop: '40px' }}>Handled potential scraps</h2>
                    <OperationsTable
                        narrow={false}
                        operations={handledOperations}
                        showSession={true}
                        operationTypeIndicators={false}
                        highlightPotentialDiscards={false}
                        showScrapLabels={true}
                        enableLabelRelease={true}
                        showOrderNumbers={true}
                        isEditableIfScrapClosed={true}
                        enableScrapClose={false}
                        showScrapCloseValues={true}
                        onEditClick={handleOperationEditClick}
                        onLabelReleaseClick={handleLabelReleaseClick}
                    />
                </>
                }
            </div>

            <OperationDialog
                isOpen={operationUnderEdit != null}
                operationUnderEdit={operationUnderEdit}
                onClose={() => setOperationUnderEdit(null)}
                onOperationSaved={onOperationSaved}
            />
            {operationUnderScrapClose && <ScrapCloseDialog
                isOpen={true}
                operation={operationUnderScrapClose}
                onClose={() => setOperationUnderScrapClose(null)}
                onSuccess={onOperationSaved}
            />}
        </>
    )
}
