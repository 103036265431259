import { DateTime } from 'luxon';
import { now } from '../../../shared/utils/dateUtils';
import { Week } from '../../types/week';


export function getPreviousWeeks(numWeeks: number): Week[] {
    const nowHelsinki = DateTime.fromJSDate(now()).setZone('Europe/Helsinki');
    const startOfCurrentWeek = nowHelsinki.startOf('week');

    const weeks: Week[] = [];
    for (let i = 1; i <= numWeeks; i++) {
        const weekStart = startOfCurrentWeek.minus({ weeks: i }).startOf('week');
        const weekEnd = startOfCurrentWeek.minus({ weeks: i - 1 }).startOf('week');
        const weekNumber = weekStart.weekNumber;

        weeks.push({
            weekNumber,
            start: weekStart.toJSDate(),
            end: weekEnd.toJSDate()
        });
    }

    return weeks.reverse();
}
