import classNames from 'classnames';
import { Position } from '../../../shared/types/protocol';
import TriStateToggle from '../../components/triStateToggle';
import { ProtocolFinal, RecordDraft } from '../../types/sharedTypeImpl';
import { evalOrNormalize } from '../../utils/positionInputParse';
import { isOutsideTolerance } from '../../utils/positionSpec';


interface RecordFormPositionsProps {
    style?: React.CSSProperties;
    protocol: ProtocolFinal;
    record: RecordDraft;
    setRecord: (record: RecordDraft) => void;
    activePositionIdx: number | null;
    setActivePositionIdx: (index: number) => void;
    isViewMode: boolean;
    setIsDirty: (isDirty: boolean) => void;
}

export default function RecordFormPositions({
    style,
    protocol,
    record,
    setRecord,
    activePositionIdx,
    setActivePositionIdx,
    isViewMode,
    setIsDirty,
}: RecordFormPositionsProps) {


    const handlePositionValueChange = (index: number, value: string | boolean) => {
        const positions = [...record.positionValues];
        positions[index] = {
            value,
            source: 'manual',
        };
        setRecord({
            ...record,
            positionValues: positions,
        });
        setIsDirty(true);
    }

    const handleGeneralInspectionSelect = (selection: boolean) => {
        setRecord({
            ...record,
            generalVisualInspections: selection,
        });
        setIsDirty(true);
    }

    return (
        <table className='measurement-form__positions' style={style}>
            <thead>
                <tr>
                    <th style={{ width: '65px' }} />
                    <th>Actual</th>
                    <th>Nominal</th>
                    <th>Upper</th>
                    <th>Lower</th>
                    <th>Tool</th>
                </tr>
            </thead>
            <tbody>
                {protocol.positions.map((it, index) =>
                    it.type === 'measurement'
                        ? <MeasurementPosition
                            key={it.positionNumber}
                            index={index}
                            position={it}
                            positionVal={record.positionValues[index]?.value as string | undefined}
                            onUpdatePositionValue={handlePositionValueChange}
                            isActive={index == activePositionIdx}
                            isViewMode={isViewMode}
                            onPositionFocus={setActivePositionIdx}
                        />
                        : <VisualInspection
                            key={it.positionNumber}
                            index={index}
                            position={it}
                            positionVal={record.positionValues[index]?.value as boolean | undefined}
                            onUpdatePositionValue={handlePositionValueChange}
                            isActive={index == activePositionIdx}
                            isViewMode={isViewMode}
                            onPositionFocus={setActivePositionIdx}
                        />
                )}
                <tr>
                    <td />
                    <td>
                        <TriStateToggle
                            selection={record.generalVisualInspections}
                            onSelectionChange={handleGeneralInspectionSelect}
                            disabled={isViewMode}
                            trueLabel='OK'
                            falseLabel='NOK'
                        />
                    </td>
                    <td colSpan={4}>
                        General visual inspections
                    </td>
                </tr>
            </tbody >
        </table >
    )
}


interface MeasurementPositionProps {
    index: number,
    position: Position,
    positionVal: string | undefined,
    onUpdatePositionValue: (index: number, value: string) => void,
    isActive: boolean,
    isViewMode: boolean,
    onPositionFocus: (index: number) => void,
}
function MeasurementPosition({
    index,
    position,
    positionVal,
    onUpdatePositionValue,
    isActive,
    isViewMode,
    onPositionFocus,
}: MeasurementPositionProps) {
    const isAuto = position.toolType === 'CMM' || position.toolType === 'VMM';
    const rowClass = classNames({
        'highlight--selected': isActive && !isAuto,
        'automatic': !isActive && isAuto,
        'automatic--selected': isActive && isAuto,
    });

    const isOutOfSpec = isOutsideTolerance(position, positionVal);
    const hasFocus = isActive && !isViewMode;
    const highlightError = isOutOfSpec && !hasFocus;


    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (/^[0-9.,*/+\-()]*$/.test(e.target.value)) {
            const valueNoCommas = e.target.value.replace(/,/g, '.');
            onUpdatePositionValue(index, valueNoCommas)
        }
    }

    const reformatOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const newValue = evalOrNormalize(value);
        onUpdatePositionValue(index, newValue);
    }


    return (
        <tr
            className={rowClass}
            onClick={() => onPositionFocus(index)}
        >
            <td>#<b>{`${position.positionNumber}`}</b></td>
            <td>
                <input
                    className={highlightError ? 'position-field--out-of-spec' : ''}
                    style={{ pointerEvents: isViewMode ? 'none' : 'auto', }}//Let clicks pass through when disabled
                    type='text'
                    value={positionVal ?? ''}
                    onFocus={() => onPositionFocus(index)}
                    onBlur={reformatOnBlur}
                    onChange={onInputChange}
                    disabled={isViewMode}
                    {...(isAuto && { tabIndex: -1 })}
                />
            </td>
            <td>{position.nominal}{position.symbol ? ` ${position.symbol}` : ''}</td>
            <td>{position.upperTolerance}</td>
            <td>{position.lowerTolerance}</td>
            <td>{position.toolType}</td>
        </tr>
    );
}


interface VisualInspectionProps {
    index: number,
    position: Position,
    positionVal: boolean | undefined,
    onUpdatePositionValue: (index: number, value: boolean) => void,
    isActive: boolean,
    isViewMode: boolean,
    onPositionFocus: (index: number) => void,
}
function VisualInspection({
    index,
    position,
    positionVal,
    onUpdatePositionValue,
    isActive,
    isViewMode,
    onPositionFocus,
}: VisualInspectionProps) {

    return (
        <tr
            className={`${isActive && 'highlight--selected'}`}
            onClick={() => onPositionFocus(index)}
        >
            <td>#<b>{`${position.positionNumber}`}</b></td>
            <td>
                <TriStateToggle
                    selection={positionVal}
                    onSelectionChange={selection => onUpdatePositionValue(index, selection)}
                    onFocus={() => onPositionFocus(index)}
                    disabled={isViewMode}
                    trueLabel='OK'
                    falseLabel='NOK'
                />
            </td>
            <td
                colSpan={4}
                title={position.description}
            >
                {position.description}
            </td>
        </tr>
    );
}
