import { useEffect, useRef, useState } from 'react';
import { fetchBatchDetails } from '../../api/requests';
import useFetchOnce from '../../hooks/useFetchOnce';
import { Operation, Session } from '../../types/sharedTypeImpl';
import { CollateralDamage } from './collateralDamage';
import { Comment } from './commentSubForm';
import { FixedTools } from './fixedToolsSubForm';
import { OperationDraft, OperationFormResult, operationToDraft } from './operationDraft';
import { getFormIds } from './operationFormshape';
import { OpSubFormRef } from './operationSubForm';
import { OperatorSubForm } from './operatorSubForm';
import { Dimension, IsScheduled, MachineComponent, MachineMainComponent, OperationType, PlusOrMinus, ToolBreakReason, ToolFailReason, ToolVariant } from './selectSubForms';
import { AdjustmentText, ConfirmedDiscards, Offset, OrderNumber, PotentialDiscards, ProgramEditText, ToolNumber } from './textSubForms';


interface OperationFormProps {
    session: Session | null;
    operationUnderEdit?: Operation | null;
    otherOperations?: Operation[];
    isSaving: boolean;
    onOperationSubmit: (operation: OperationFormResult) => void;
}

export default function OperationForm({
    session,
    operationUnderEdit,
    otherOperations,
    isSaving,
    onOperationSubmit,
}: OperationFormProps) {
    const [operation, setOperation] = useState<OperationDraft>(
        operationUnderEdit
            ? operationToDraft(operationUnderEdit)
            : { details: [] }
    );
    const [areFinalFieldsVisible, setAreFinalFieldsVisible] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const subformRefs = useRef(new Map<number, OpSubFormRef>(null));

    const { formIds, isFormComplete } = getFormIds(operation);


    // Set default order number for new operations
    useFetchOnce({
        queryKey: ['current-batch-details', session?.equipment, session?.product, session?.start.toISOString()],
        queryFn: () => fetchBatchDetails(session!.equipment, session!.product, session!.start),
        enabled: !!session && operationUnderEdit == null,
        onSuccess: (batchDetails) => {
            if (!batchDetails?.orderNumber) return;
            setOperation((operation) => ({ ...operation, orderNumber: batchDetails.orderNumber }));
        },
    })

    useEffect(() => {
        if (isFormComplete) {
            setAreFinalFieldsVisible(true);
        }
    }, [isFormComplete]);


    const setRef = (key: number, ref: OpSubFormRef | null) => {
        if (ref) subformRefs.current.set(key, ref);
        else subformRefs.current.delete(key);
    }

    const handleChange = (newOperation: OperationDraft) => {
        setOperation(newOperation);
    };

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!formRef.current?.reportValidity()) return;

        const operation: Partial<OperationFormResult> = {};
        const keys = Array.from(subformRefs.current.keys()).sort();
        for (const key of keys) {
            if (!subformRefs.current.get(key)!.validateWarnAndGet(operation)) return;
        }

        onOperationSubmit(operation as OperationFormResult);
    }


    return (
        <form ref={formRef}>
            {formIds.map((it, index) => (
                it === 'operationType' ? (
                    <OperationType key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'machineComponent' ? (
                    <MachineComponent key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'machineMainComponent' ? (
                    <MachineMainComponent key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'plusOrMinus' ? (
                    <PlusOrMinus key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'adjustmentText' ? (
                    <AdjustmentText key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'toolVariant' ? (
                    <ToolVariant key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'toolNumber' ? (
                    <ToolNumber key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'dimension' ? (
                    <Dimension key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'offset' ? (
                    <Offset key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'collateralDamage' ? (
                    <CollateralDamage key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'isScheduled' ? (
                    <IsScheduled key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'toolFailReason' ? (
                    <ToolFailReason key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'toolBreakReason' ? (
                    <ToolBreakReason key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'programEditText' ? (
                    <ProgramEditText key={it} operation={operation} onOperationChange={handleChange} ref={(r) => setRef(index, r)} />
                ) : it === 'fixedTools' ? (
                    <FixedTools
                        key={it}
                        ref={(r) => setRef(index, r)}
                        operation={operation}
                        onOperationChange={handleChange}
                        operationUnderEdit={operationUnderEdit}
                        otherOperations={otherOperations}
                    />
                ) : null
            ))}

            {areFinalFieldsVisible && <>
                <Comment operation={operation} onOperationChange={handleChange} ref={(r) => setRef(1001, r)} />
                <PotentialDiscards operation={operation} onOperationChange={handleChange} ref={(r) => setRef(1002, r)} />
                <ConfirmedDiscards operation={operation} onOperationChange={handleChange} ref={(r) => setRef(1003, r)} />
                <OrderNumber operation={operation} onOperationChange={handleChange} ref={(r) => setRef(1004, r)} />
                <OperatorSubForm operation={operation} onOperationChange={handleChange} ref={(r) => setRef(1005, r)} />

                <div className='dialog--button-bar'>
                    <button className='button--large' disabled={isSaving} onClick={handleSubmit}>
                        {isSaving ? 'Submitting...' : 'Submit'}
                    </button>
                </div>
            </>}
        </form>
    );
}
