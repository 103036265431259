import { useState } from 'react';
import ProtocolMetaFields from '../../components/protocol-form/protocolMetaFields';
import { ProtocolFinal, RecordWithProtocol } from '../../types/sharedTypeImpl';
import { formatDatetime } from '../../utils/fieldFormats';


interface OverviewProps {
    orderNumber: string | undefined;
    protocol: ProtocolFinal;
    batchRecords: RecordWithProtocol[];
    enabledIssues: number | undefined;
    allIssues: number | undefined;
}

export default function Overview({
    orderNumber,
    protocol,
    batchRecords,
    enabledIssues,
    allIssues,
}: OverviewProps) {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const latestRecord = batchRecords[batchRecords.length - 1];

    const issuesText = enabledIssues != null && allIssues != null
        ? `${enabledIssues} / ${allIssues}`
        : '';

    return (
        <table className='record-form--table'>
            <tbody>
                {!isCollapsed && <ProtocolMetaFields
                    protocol={protocol}
                    disabled={true}
                />}
                {isCollapsed && <tr>
                    <td>Part number</td>
                    <td><input type='text' value={protocol?.partDisplayName} disabled /></td>
                </tr>}
                {!isCollapsed && <tr>
                    <td>Order number</td>
                    <td><input type='text' value={orderNumber ?? ''} disabled /></td>
                </tr>}
                <tr>
                    <td>Equipment</td>
                    <td><input type='text' value={latestRecord.equipmentDisplayName} disabled /></td>
                </tr>
                {!isCollapsed && <tr>
                    <td>Batch start</td>
                    <td><input type='text' value={formatDatetime(latestRecord.sessionStart)} disabled /></td>
                </tr>}
                <tr>
                    <td>Measurements in batch</td>
                    <td><input type='text' value={batchRecords.length} disabled /></td>
                </tr>
                {!isCollapsed && <tr>
                    <td>Latest measurement</td>
                    <td><input type='text' value={formatDatetime(latestRecord.measurementStart)} disabled /></td>
                </tr>}
                {!isCollapsed && <tr>
                    <td>Issues</td>
                    <td><input type='text' value={issuesText} disabled /></td>
                </tr>}
                <tr>
                    <td colSpan={2}>
                        <button onClick={() => setIsCollapsed(!isCollapsed)} >
                            {isCollapsed ? 'Show more' : 'Show less'}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
