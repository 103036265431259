import { httpBatchLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import type { AppRouter } from '../../back/app';
import { trpcTransformer } from '../../shared/utils/trpcTransformer';


export const trpc = createTRPCReact<AppRouter>();

export const trpcClient = trpc.createClient({
    links: [
        httpBatchLink({
            url: '/trpc',
            transformer: trpcTransformer,
        }),
    ],
});
