import { useQuery } from '@tanstack/react-query';
import { DiagnosticsData } from '../../../shared/types/diagnosticsData';
import { Alarm } from '../../../shared/types/mtlinkiTypes';
import { OverlookEntry } from '../../../shared/types/overlookEntry';
import { fetchDiagnostics, fetchOverlookTable } from '../../api/requests';
import NavBar from '../../components/navBar';
import { PageTitle } from '../../components/pageTitle';
import { useFullScreenToggle } from '../../hooks/useFullscreenToggle';
import { formatBatchTimeRemaining } from '../../utils/fieldFormats';
import getEquipmentCoords from '../../utils/getEquipmentCoords';
import getStatusColor from '../../utils/getStatusColor';
import getStatusMarker from '../../utils/getStatusMarker';
import { getSessionTimeRemainingStyle } from '../../utils/styles';


export default function Overlook() {

    const { data: overlookTable } = useQuery({
        queryKey: ['overlookTable'],
        queryFn: fetchOverlookTable,
        refetchInterval: 1_000,
    });

    const { data: diagnosticsResults } = useQuery({
        queryKey: ['diagnostics'],
        queryFn: fetchDiagnostics,
        refetchInterval: 30_000,
    });

    const { isFullscreen } = useFullScreenToggle();

    return (
        <>
            <PageTitle title='Overlook' />
            {!isFullscreen && <NavBar />}
            <div className='content'>
                {overlookTable == null
                    ? 'Loading...'

                    : <div className='equipment-grid'>
                        <OverlookStatus overlookTable={overlookTable ?? []} />
                        {overlookTable?.map((it: OverlookEntry) =>
                            <OverlookCell key={it.equipment} it={it} />
                        )}
                        <ServerHealth diagnosticsData={diagnosticsResults} />
                    </div>
                }
            </div>
        </>
    )
}


interface OverlookStatusProps {
    overlookTable: OverlookEntry[];
}
function OverlookStatus({
    overlookTable,
}: OverlookStatusProps) {
    const runningCount = overlookTable.filter(it => it.status === 'OPERATE' || it.status === 'SETUP').length;
    const totalCount = overlookTable.filter(it => it.status).length;
    const percentRunning = totalCount ? runningCount / totalCount * 100 : 0;

    return (
        <div
            className='overlook-stats'
            style={{
                gridColumn: `col1 / span 2`,
                gridRow: '1 / span 2',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div style={{ fontSize: '1.5em', fontWeight: 'bold', }}>
                Running: {runningCount} / {totalCount}
            </div>
            <div style={{ fontSize: '6em' }}>
                {percentRunning.toFixed(0)}%
            </div>
        </div>
    )
}

interface ServerHealthProps {
    diagnosticsData: DiagnosticsData | undefined;
}
function ServerHealth({
    diagnosticsData,
}: ServerHealthProps) {
    if (!diagnosticsData) return null;
    return (
        <div
            className='overlook-stats'
            style={{
                gridColumn: `col5 / span 2`,
                gridRow: '6 / span 1',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '20px',
            }}
        >
            <div style={{ fontSize: '1.5em', textAlign: 'center', marginTop: '12px' }}>
                {getStatusMarker(diagnosticsData?.overallStatus)} {diagnosticsData?.conclusion}
            </div>
        </div>
    )
}


interface OverlookCellProps {
    it: OverlookEntry;
}
function OverlookCell({
    it
}: OverlookCellProps) {
    const coords = getEquipmentCoords(it.equipment);
    if (!coords) return null;

    const showWarnings = it.status !== 'DISCONNECT';
    const warningMessage = showWarnings
        ? it.warning ?? (!it.isFullySynced ? 'Not fully synced' : null)
        : null;

    const showPartCounts = it.status !== 'DISCONNECT' && it.partCount != null;
    const potentialScrapText = it.potentialScraps && it.potentialScraps > 0 ? ` (-${it.potentialScraps})` : '';

    const timeRemainingMillis = it.sessionEndPrediction ? +it.sessionEndPrediction - Date.now() : null;
    const showTimeRemaining = showPartCounts && timeRemainingMillis != null;
    const timeRemaining = (showTimeRemaining)
        ? formatBatchTimeRemaining(timeRemainingMillis)
        : '\u00A0';//non-breaking space to keep row from collapsing
    const timeRemainingStyle = showTimeRemaining
        ? getSessionTimeRemainingStyle(timeRemainingMillis, it.partCount, it.targetQuantity, it.inOfflineMode)
        : {};

    const showAlarms = it.status !== 'DISCONNECT' && it.alarms?.length;
    const alarmRows = showAlarms
        ? ensureArrayLength(it.alarms?.filter(it => it.message) ?? [], 4) as Array<Alarm | null>
        : new Array(4).fill(null);


    return (
        <table
            className='equipment-status'
            style={{
                gridColumn: `col${coords.col + 1}`,
                gridRow: coords.row + 1,
                tableLayout: 'fixed',
                width: '100%',
                border: `5px solid ${getStatusColor(it.status)}`,
                backgroundColor: `${getStatusColor(it.status)}18`,
                fontWeight: 'bold',
            }}
        >
            <tbody>
                <tr>
                    <td title={it.equipment}>{it.equipmentDisplayName}</td>
                    <td style={{ backgroundColor: it.status ? `#${getStatusColor(it.status)}` : '' }}>
                        {it.status}
                    </td>
                </tr>
                <tr>
                    <td title={it.product}>{it.partDisplayName}</td>
                    <td className={getWarningClass(it.warning, it.status)}>
                        {warningMessage}
                    </td>
                </tr>
                <tr>
                    <td>
                        {showPartCounts && <>
                            {it.partCount}
                            <span style={{ color: 'red' }}>{potentialScrapText}</span>
                            {it.targetQuantity && <span> / {it.targetQuantity}</span>}
                        </>}
                    </td>
                    <td style={timeRemainingStyle}>{timeRemaining}</td>
                </tr>

                {alarmRows.map((alarm, index) => {
                    return (
                        <tr key={index}>
                            <td colSpan={2} className='alarms-list--cell' title={alarm?.message}>
                                <div className={getAlarmClass(alarm, it.status)}>
                                    {alarm?.message ?? '\u00A0'}
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

/* Filter and pad an array to a specific length */
function ensureArrayLength<T>(arr: T[], length: number): Array<T | null> {
    const filtered = arr.slice(arr.length - length);
    const padding: null[] = Array.from({ length: length - filtered.length }, () => null);
    return [...filtered, ...padding];
}

function getAlarmClass(alarm: Alarm | null, status: string | null): string {
    return !alarm || alarm.end !== null || status === 'DISCONNECT' || status === 'SETUP'
        ? 'equipment-status__alarm--inactive'
        : 'equipment-status__alarm--active';
}

function getWarningClass(warning: string | null, status: string | null): string {
    return warning == null || status === 'DISCONNECT' || status === 'SETUP'
        ? 'equipment-status__alarm--inactive'
        : 'equipment-status__alarm--active';
}
