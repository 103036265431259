import { useState } from 'react';
import { CmmReportHeader } from '../../../shared/types/cmmReport';
import { formatDatetime } from '../../utils/fieldFormats';
import ModalSubmitDialog from '../modalSubmitDialog';
import ToggleButton from '../toggleButton';


interface CmmReportExt extends CmmReportHeader {
    index: number;
    isSelected: boolean;
}

interface CmmFilesDialogProps {
    isOpen: boolean;
    cmmReports: CmmReportHeader[];
    initialFilter?: string;
    onCancel: () => void;
    onSubmit: (filenames: string[]) => void;
}

export default function CmmFilesDialog({
    isOpen,
    cmmReports: _cmmReports,
    initialFilter,
    onCancel,
    onSubmit,
}: CmmFilesDialogProps) {
    const [cmmReports, setcmmReports] = useState<CmmReportExt[]>(
        _cmmReports.map((it, index) => ({
            ...it,
            index,
            isSelected: false,
        }))
    );
    const [filterInput, setFilterInput] = useState(initialFilter ?? '');


    const handleSelectionChange = (index: number, isSelected: boolean) => {
        const cmmReports2 = [...cmmReports];
        cmmReports2[index].isSelected = isSelected;
        setcmmReports(cmmReports2);
    };

    const handleSubmit = () => {
        const filenames = cmmReports.filter(it => it.isSelected).map(it => it.name);
        onSubmit(filenames);
    };


    const filteredCmmReports = cmmReports.filter(it => it.name.toLowerCase().includes(filterInput.toLowerCase()));

    if (!isOpen) return null;
    return (
        <ModalSubmitDialog isOpen={isOpen} onCancel={onCancel} onSubmit={handleSubmit}>
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <label>Filter:</label>
                <input
                    style={{ marginLeft: '5px' }}
                    type='text'
                    placeholder='Search'
                    value={filterInput}
                    onChange={e => setFilterInput(e.target.value)}
                />
                <button className='button--clear-filters' onClick={(e) => { setFilterInput('') }}>
                    <img src='/clear-filter-icon.svg' alt='Clear filters' />
                </button>
            </div >

            <table className='table--cmm-reports-list'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Time</th>
                        <th>Import</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredCmmReports?.map(it => (
                        <tr key={it.name}>
                            <td>{it.name}</td>
                            <td>{formatDatetime(it.lastModified)}</td>
                            <td>
                                <ToggleButton
                                    selection={it.isSelected}
                                    onSelectionChange={(isSelected) => handleSelectionChange(it.index, isSelected)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </ModalSubmitDialog >
    )
}
