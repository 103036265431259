import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { fetchMeasurementDelays, fetchOee, fetchOperations } from '../../api/requests';
import NavBar from '../../components/navBar';
import OeeGraphControls from '../../components/oee-graph-controls/oeeGraphControls';
import { OeeGraphOptions } from '../../components/oee-graph-controls/oeeGraphOptions';
import { PageTitle } from '../../components/pageTitle';
import AvailabilityReportGraph from '../../components/reporting-tool-graph/availabilityReportGraph';
import MeasurementDelaysGraph from '../../components/reporting-tool-graph/measurementDelaysGraph';
import ScrapsTimeGraph from '../../components/reporting-tool-graph/scrapsTimeGraph';
import { getPreviousWeeks } from './getPreviousWeeks';
import ReportTypeSelection from './reportTypeSelection';
import { ReportTypeKey } from './reportTypes';


export default function ReportingTool() {

    const [reportType, setReportType] = useState<ReportTypeKey>('availability');
    const [oeeGraphOptions, setOeeGraphOptions] = useState<OeeGraphOptions>({
        visibleShifts: [
            'morning',
            'evening',
            'night',
        ],
        excludeWeekends: false,
    });

    const weeks = useMemo(() => getPreviousWeeks(5), []);
    const startDate = weeks[0].start;
    const endDate = weeks.at(-1)!.end;

    const { data: oeeData } = useQuery({
        queryKey: ['graphs-data/oee', 'day', 'All equipments', startDate],
        queryFn: async () => await fetchOee({ windowType: 'shift', equipment: 'All equipments', start: startDate, }),
        refetchOnWindowFocus: false,
    });

    const { data: measurementDelays } = useQuery({
        queryKey: ['graphs-data/measurement-delays', startDate, endDate],
        queryFn: async () => await fetchMeasurementDelays(startDate, endDate),
        refetchOnWindowFocus: false,
    });

    const { data: scrapCloseOperations } = useQuery({
        queryKey: ['operations', 'start', startDate, 'end', endDate, 'hasScrapCloseForm', true],
        queryFn: async () => await fetchOperations({ start: startDate, end: endDate, hasScrapCloseForm: true }),
        refetchOnWindowFocus: false,
    });


    return (<>
        <PageTitle title='Reporting' />
        <NavBar />
        <div className='content'>
            <div style={{ height: '90vh', display: 'flex', flexDirection: 'row' }}>
                <div style={{ minWidth: '300px', width: '25%', fontSize: '20px', marginTop: '24px' }}>
                    <ReportTypeSelection
                        selectedReportType={reportType}
                        onReportTypeSelected={setReportType}
                    />

                    {reportType === 'availability' &&
                        <OeeGraphControls
                            style={{ marginTop: '64px' }}
                            shiftOptionsOnly={true}
                            showHeader={true}
                            oeeGraphOptions={oeeGraphOptions}
                            setOeeGraphOptions={setOeeGraphOptions}
                        />
                    }
                </div>

                {reportType === 'availability' && <>
                    {!oeeData
                        ? 'Loading...'
                        : <AvailabilityReportGraph
                            style={{ width: '50%' }}
                            oeeData={oeeData}
                            oeeGraphOptions={oeeGraphOptions}
                            weeks={weeks}
                        />
                    }
                </>}

                {reportType === 'delays' && <>
                    {!measurementDelays
                        ? 'Loading...'
                        : <MeasurementDelaysGraph
                            style={{ width: '50%' }}
                            measurementDelays={measurementDelays}
                            weeks={weeks}
                        />
                    }
                </>}

                {reportType === 'scraps_time_spent' && <>
                    {!scrapCloseOperations
                        ? 'Loading...'
                        : <ScrapsTimeGraph
                            style={{ width: '50%' }}
                            operations={scrapCloseOperations}
                            weeks={weeks}
                        />
                    }
                </>}
            </div>
        </div>
    </>);
}
